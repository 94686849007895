.forcard-blog-detail {
  border: none;
  width: 100%;
  position: relative;
  border-radius: 20px 20px 10px 12px;
  background-color: white;
}
.main_img{
  height: 460px !important;
 }
@media (min-width: 1400px) {
  .forcard-blog-detail {
    border: none;
    width: 97%;
    position: relative;
    border-radius: 20px 20px 10px 12px;
    background-color: white;
  }
}
@media (min-width: 991px) {
 .main_img{
  height: 460px !important;
 }
}

.cardss {
  flex-shrink: 0;
  width: 100%;
  position: relative;
  height: 300px !important;
}

.Blog-hr-line-var {
  position: absolute;
  right: 0px;
  width: 1px;
  top: 0px;
  height: calc(100% - 3rem);
  border: 0.5px solid #a19f9f;
  /* transform: rotate(90deg); */
  /* display: inline-block; */
}

.overlay-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  text-align: center;
  color: #fff;
  /* width: 100%; */
  font-size: 500px;
  font-weight: 700;
  font-weight: bold;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 6px
}

.overlay-text {
  /* Style for additional text below blogd1 image */
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  width: 100%;
  padding: 10px;
  text-align: center;
}

.custom-large-text {
  font-family: "Inter", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: left;
}

.custom-small-text {
  color: #828282;
  text-align: justify;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 166.667% */
  letter-spacing: 0.04em;
}

.order-list-text1 {
  padding-left: 1rem;
}

.card-text.custom-small-text strong {
  font-weight: bold;
  color: rgb(19, 17, 17);
  letter-spacing: 0.04em;
}

.responsive-box {
  border: 1px solid black;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  margin-top: 20px;
}

.custom-box-paragraph {
  font-family: 'Inter', sans-serif; 
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: justified;
}

.card-text {
  margin-bottom: 10px;
  position: relative;
}

.blog-details-img-text {
  font-size: 18px;
  font-weight: 600;
  text-align: start;
  padding-left: 8%;
  padding-right: 3%;
  letter-spacing: 0.9px;
}

@media (max-width: 480px) {
  .text1small {
    margin-top: 5%;
  }
  .small-view{
    margin-top: 0% !important;
  }
  .textsmall1 {
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
  }
  .responsive-box {
    padding: 14px;
  }
  .custom-box-paragraph {
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
  }

  .custom-large-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 1%;
  }
  .main_img{
    height: 100% !important;
   }
   .cardss {
    flex-shrink: 0;
    width: 100%;
    position: relative;
    height: 100% !important;
    aspect-ratio: 3 / 3;
  }
  .blog-details-img-text {
    font-size: 15px;
    font-weight: 700;
    text-align: center !important;
    padding-right: 3%;
    letter-spacing: 0.9px;
 }
}
