.career-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.career-title {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.career-description {
    font-size: 1.2em;
    color: #555;
    text-align: center;
    margin-bottom: 30px;
}

.career-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.career-section {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
    font-size: 1.5em;
    color: #444;
    margin-bottom: 10px;
}

.section-content {
    font-size: 1em;
    color: #666;
    line-height: 1.6;
}

.experience-list {
    list-style-type: disc;
    margin-left: 20px;
}

.experience-item {
    font-size: 1em;
    color: #666;
    margin-bottom: 5px;
}
