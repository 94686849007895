.for_picture {
  background-color: #ffeac9;
}
.international-departure-section {
  margin-bottom: 4rem !important;
}
.stit {
  color: #dc1b5e;
  font-size: 28px !important;
}
.for_heading {
  font-size: 60px;
  color: rgba(0, 0, 0, 1);
}

.for_headingedit {
  text-align: center;
  line-height: 48px;
  font-size: 40px;
  color: #dc1b5e;
  font-weight: 700;
}

@media only screen and (max-width: 576px) {
  .backgroundimg {
    background-image: url(/src/assets/images/background.png);
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    align-items: center;
    justify-content: center;
    display: flex;
    background-repeat: no-repeat;
    margin: 0;
    height: 300px !important;
    background-size: cover;
  }
  .stit {
    color: #dc1b5e;
    font-size: 18px !important;
  }
  .backgroundcontentunder {
    background: rgba(255, 234, 201, 0.5);
    width: 50%;
    text-align: center !important;
    margin: auto;
    padding: 4% !important;
  }

  .for_h3line {
    font-size: 23px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(220, 27, 94, 1);
  }

  .didnothr {
    width: 100% !important;
    border: 1px solid rgba(255, 169, 35, 1);
    opacity: 1;
    margin: 0px;
  }

  .for_here {
    font-size: 22px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 2%;
  }

  .connectbtn {
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(255, 169, 35, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 43px;
    padding: 3% 8% 3% 8% !important;
    margin-top: 8%;
  }
}

@media only screen and (max-width: 992px) {
  .flight_bx {
    text-align: center !important;
  }

  .for_headingedit {
    text-align: center;
    line-height: 48px;
    font-size: 30px;
    color: #dc1b5e;
    font-weight: 700;
  }
}

.for_places {
  font-size: 200%;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
}

.for_cards {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 270px !important;
  width: 290px !important;
  border-radius: 15px !important;
  object-fit: cover;
  cursor: pointer;
}

.for_cards_featured {
  /* position: relative; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 10px; */
  height: 273px !important;
  /* width: 250px !important; */
  border-radius: 15px !important;
  object-fit: cover;
  cursor: pointer;
}

.custom-select {
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-select::-webkit-scrollbar {
  display: none;
}

.for_cards_personalized {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 243px !important;
  border-radius: 15px !important;
  object-fit: cover !important;
  cursor: pointer;
}

.for_under_card {
  position: absolute;
  bottom: 0px;
  left: 74px;
  color: white;
  font-size: 39px;
  text-align: center;
}

.for_content {
  font-weight: 700;
  font-size: 310%;
  line-height: 48.41px;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}

.for_content2 {
  text-align: justify;
  font-weight: 400;
  color: #828282;
  line-height: 23.78px;
  font-size: 19px;
}

.for_button {
  padding: 4px 25px;
  border-radius: 17.38px;
  border: 1.39px solid #ffa923;
  color: #ffa923;
  background-color: white;
  margin: 5px;
}

.for_button.active {
  background-color: #ffa923 !important;
}

.for_button:hover {
  background: #ffa923;
  color: white;
}

.For_Card_body {
  position: relative;
}

.For_Card_body_under {
  position: absolute;
  font-family: Inter;
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  bottom: 10px;
  left: 51px;
  color: white;
}

.for_treaking {
  font-size: 37px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.14em;
  text-align: left;
  color: #dc1b5e;
}

.for_treaking2 {
  font-size: 37px;
  font-weight: 700;
  letter-spacing: 0.14em;
  line-height: 24px;
  color: #dc1b5e;
}

.for_treakingfeature {
  margin-top: 10%;
  font-size: 256%;
  font-weight: 600;
  line-height: 47px;
  letter-spacing: 0em;
}

.travel_content {
  color: #888888;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 15px 25px 0px 0px;
}

.for_package {
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  font-size: 24px;
  padding-top: 8%;
}

.for_departure {
  margin-left: 10px;
  text-align: justify;
  font-size: 200%;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  letter-spacing: 0.05em;
}

.for_departure2 {
  color: #dc1b5e;
}

.for_international_p {
  position: absolute;
  bottom: 0px;
  left: 16px;
  text-align: justify;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  color: rgba(255, 255, 255, 1);
}

.for_content_inner {
  position: absolute;
  bottom: 14px;
  color: white;
  right: 0px;
  width: 100%;
  border-radius: 0px 0px 15px 15px;
  padding: 10px;
}

.flex_center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.for_content_inner2 {
  border-radius: 15px;
  position: absolute;
  bottom: 0px;
  color: white;
  /* right: 0px; */
  height: 30%;
  width: 100%;
  padding: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
}

.for_content_inner3 {
  width: 100%;
  position: absolute;
  bottom: 12px;
  text-align: center;
}

.inter_pkg {
  height: 30%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  width: 100%;
}

.for_viewmore {
  text-align: justify;
  padding-top: 8%;
}

.for_viewMore {
  text-align: justify;
  background-color: #ffa923;
  color: white;
  border-radius: 15.69px;
  padding: 14px 40px 14px 40px;
  border-radius: 13.69px;
  border: none;
  font-size: 21px;
  color: rgba(255, 255, 255, 1);
}

.for_weekandcontent {
  position: absolute;
  bottom: 45px;
  left: 16px;
  text-align: left;
  font-size: 20px;
  cursor: pointer;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(255, 255, 255, 1);
}

._hrr {
  font-size: 30px;
  font-weight: 700;
}

._hrprice {
  font-size: 24px;
  font-weight: 700;
}

.for_packbutton {
  position: absolute;
  bottom: 12px;
  text-align: justify;
  left: 12px;
  padding: 4px 12px 4px 12px;
  border: none;
  background-color: #ffa923;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.for_hr {
  border: 2px solid #000000;
}

.for_bgdiv {
  background: #ffeac9;
}

.find_tourbtn {
  margin-bottom: 10px;
  padding: 10px 25px;
  border: none;
  border-radius: 22px;
  background: #ffa923 !important;
  color: rgba(255, 255, 255, 1);
  background: rgba(255, 169, 35, 1) !important;
  border: rgba(255, 169, 35, 1);
  box-shadow: 4px -2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.for_content_top {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 100%;
  color: white;
  top: 0%;
}

.for_content_top2 {
  position: absolute;
}

.for_boxcontent {
  background: #ffa923;
  border-radius: 0px 4px 27px 2px;
  width: 100%;
  font-size: 25px;
  padding: 4px 25px 3px 19px;
  box-shadow: -2px -2px 4px 0px #00000040 inset;
  color: white;
}

.for_boxcontent2 {
  background: #dc1b5e;
  border-radius: 0px 4px 27px 2px;
  width: 100%;
  font-size: 25px;
  padding: 4px 25px 3px 19px;
  box-shadow: -2px -2px 4px 0px #00000040 inset;
  color: white;
}

.for_button_under {
  position: absolute;
  bottom: 25px;
  left: 83px;
  border-radius: 15px;
  padding: 2px 12px 2px 12px;
  background: #ffa923;
  border: 0;
}

.Weekend_img {
  background: rgba(0, 0, 0, 0.4);
  /* filter: brightness(0.7); */
}

.International_img {
  background: rgba(0, 0, 0, 1);
  /* filter: brightness(0.7); */
}

.internationalcity {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: rgba(255, 255, 255, 1);
}

.for_box_content_main {
  position: relative;
}

.for_box_content_child {
  transform: translateX(-50%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 111px;
  left: 50%;
  background: #ffeac980;
  text-align: center;
  padding: 40px;
  background: #ffeac980;
}

.for_number {
  color: #000;
  border-radius: 100%;
  background-color: #ffa923;
}

.for_aboutoffer {
  width: 78%;
  text-align: justify;
  color: rgba(130, 130, 130, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: justified;
}

.chooseus {
  text-align: -webkit-left;
  font-size: 32px;
  font-weight: 700;
}

.for_travelpackage {
  font-size: 18px;
  font-weight: 700;
  font-family: inter, sans-serif;
}

.for_backimgparent {
  position: relative;
}

.for_connectbtn {
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  padding: 15px 25px 13px 25px;
  border-radius: 50px;
  border: none;
  background: #ffa923;
  color: white;
}

.for_firstcontent {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #dc1b5e;
}

.for_misery {
  color: black;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}

.for_backimgchild {
  position: absolute;
  left: -70px;
  bottom: 61px;
  top: 4rem;
}

.for_first_about {
  font-size: 18px;
  text-align: start;
  color: #828282;
  padding: 15px 101px 20px 0px !important;
}

.for_find_btn {
  background-color: white;
  border: 2px solid #ffcb7a;
  font-size: 18px;
  color: #000;
  text-decoration: none;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  padding: 13px 20px;
  border-radius: 13px;
  background-color: white;
}

.for_find_btn:hover {
  color: #000;
}

.find_tourbtn {
  box-shadow: 4px -2px 4px 0px #00000040 inset;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.for_btn_btn {
  text-align: start;
  margin-bottom: 3% !important;
}

.for_Under {
  border-radius: 227px 215px 6px 3px;
}
.find_btn {
  margin-top: -4px;
}
.for_cityname {
  font-size: 35px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.for_select {
  border: 1.5px solid #ffcb7a;
  margin: auto;
  width: 92%;
  border-radius: 9px;
  cursor: pointer;
  padding: 3px 20px 3px !important;
}

.testimonial_content {
  font-size: 18px;
  font-weight: 400;
  font-family: ubantu;
  text-align: justify;
}

.testimonial_outer {
  border-width: 6.03px;
  border-style: solid;
  border-color: #ffa923;
  padding: 55px;
  border-radius: 12px 80px 12px 95px;
}

.subtract_contain {
  position: relative;
}

.subtract_contain2 {
  position: absolute;
  bottom: 110px;
  text-align: justify;
  left: 98px;
  right: 101px;
}

.ParentUdraw {
  position: relative;
  height: 100%;
}

.Udraw2 {
  height: 100%;
  border: 1px solid black;
  border-radius: 560px 560px 0px 0px;
  margin-right: -35px;
}

.ChildUdraw {
  bottom: 18px;
  left: 18px;
  height: 100%;
  width: 100%;
  padding: 15px;
  min-height: 550px;
}

.Under_img {
  border-radius: 560px 560px 0px 0px;

  height: 100%;

  object-fit: cover;
  width: 100%;
}

for_hrline2 {
  border: 3px solid #ffa923 !important;
  background-color: #ffa923 !important;
  height: 3px !important;
  width: 38% !important;
  /* opacity: 1; */
}

.for_personalizedtrips {
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
}

.for_personalized {
  border-radius: 0px;
}

.test_text {
  font-size: 40px;
  font-weight: 600;
  color: #dc1b5e;
}

.for_grouptrip {
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
}

.Stop_expoloring {
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: black;
}

.for_hrline {
  border: 2px solid #ffa923;
  background: #ffa923;
  position: relative;
}

.for_hrline::before {
  content: " ";
  display: inline-block;
  width: 19px;
  height: 5px;
  background-color: #f2184f;
}

.for_hrline2 {
  border: 2px solid #ffa923;
  background: #ffa923;
  position: relative;
}

.for_hrline2::before {
  content: " ";
  display: inline-block;
  width: 19px;
  height: 5px;
  background-color: #f2184f;
}

.for_hrline2 {
  border: 2px solid rgba(255, 169, 35, 1);
  opacity: 1;
  width: 36%;
}

.Trackiing-img-slide-text {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  bottom: 0px;
  color: white;
  font-size: 18px;

  margin: auto;

  text-align: center;
  color: rgba(255, 255, 255, 1);
  padding: 4px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.find-tracking-img-slide-div {
  filter: brightness(0.8);
}

.tracking-img-text-slide {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.v_inner_txt2 {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
  font-size: 58%;
}

.v_inner_txt {
  position: absolute;
  width: 161px;
  height: 30%;
  bottom: 0px;
  color: white;
  font-size: 28px;
  text-align: center !important;
  font-weight: 700;
  border-radius: 15px;
}

.swiper-slide {
  text-align: center;
  font-size: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  font-family: "Inter";
}

.cardimgg {
  height: 150px !important;
}

.swiper-slide img {
  width: 100%;
  cursor: pointer;
}

.less-icon-gola {
  border-radius: 100%;
  border: 1px solid black;
  padding: 1px 7px 4px 5px;
}

.slider-icon-gol-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.react-multi-carousel-track {
  list-style: none;

  padding: 0px;
  margin: 0 0 52px 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}

.react-multi-carousel-dot button {
  display: inline-block;
  width: 35px;
  height: 9px;
  border-radius: 18%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  border-width: 2px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
  border: none;
}

.react-multi-carousel-dot--active button {
  background: #ffa923;
}

.active {
  /* background-color: #ffa923 !important; */
  color: #fff !important;
}

.backpaking_box {
  position: relative;
  margin: 40px;
  height: 660px;
}

.backpacking-pink {
  position: absolute;
  width: 97%;
  height: 651px;
  background-color: #dc1b5e !important;
  border-radius: 0px 90px 90px 0px;
  z-index: -6;
  left: -152px !important;
}

.backpacking-light-yellow {
  position: absolute;
  width: 87%;
  height: 651px;
  background: #ffeac9;
  border-radius: 0px 90px 90px 0px;
  z-index: -5;
  left: -130px !important;
  top: -20px !important;
  margin-left: 106px;
}

.backpacking-text-div {
  display: flex;
  justify-content: start;
  align-items: start;

  text-decoration: underline;
}

.backpacking-text {
  font-size: 200%;
  line-height: 48px;
  font-weight: 700;

  color: #dc1b5e;
  text-decoration: underline;
  height: 10%;
  margin: 20px;
  text-decoration-color: black;
}

.backpacking-trip-text {
  line-height: 48px;
  font-weight: 700;
  letter-spacing: 5%;
  color: black;
}

.backpacking-imag-div {
  display: flex;
  justify-content: start;
  align-items: start;
}

.backpakin-btan-divi {
  width: 100%;
  background-color: #ffa923;
  border-radius: 32px;
  border: none;
  color: #fff;
  font-weight: 600;
  text-align: center;
  margin-top: 25px;
  padding-top: 4px;
}

.mainbox {
  box-sizing: border-box;
}

.weekand_parent {
  position: relative;
  height: 381px !important;
  /* width: 255px !important; */
  /* min-height: 341px !important; */
  width: 100%;
  object-fit: cover;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: -10px;  */
}

.for_hrr {
  text-align: center;
  opacity: 1;
  width: 21% !important;
  border: 2px solid rgba(0, 0, 0, 1);
}

.Europe_trip {
  height: 30%;
  position: absolute;
  bottom: 0;
  color: white;
  font-size: 19px;
  padding: 0% 7% 7% 7%;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  width: 95%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
}

.europecontent {
  font-size: 23px;
  font-weight: 600;
  /* line-height: 19px; */
  /* letter-spacing: 0em; */
  text-align: center;
  height: 50%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
  /* overflow: hidden; */
}

.europecontent2 {
  font-size: 20px;
  font-weight: 600;
  /* line-height: 23px;
  letter-spacing: 0em; */
  text-align: center;
  height: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* overflow: hidden; */
}

.tips_uper {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 7px;
  color: white;
  opacity: 1.6;
}

.for_btn_enquiry {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  border-radius: 15px;
  padding: 7px 16px 7px 16px;
  border: none;
  background: #ffa923;
}

.exploremore {
  text-align: end;
  font-size: 200%;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
}

.exploremore_link {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}

.forhovereffect:hover {
  color: rgba(220, 27, 94, 1);
}

.for_secondpersonalized {
  font-size: 15px;
  font-weight: 500;
  line-height: 21.78px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 30%;
  color: rgba(0, 0, 0, 1);
}

.card-img-top-featured {
  border-radius: 20px !important;
}

.card-img-top-international {
  aspect-ratio: 3/4;
  width: 100%;
  height: 100%;
  border-radius: 15px !important;
  object-fit: cover !important;
  cursor: pointer;
}

.btn:focus {
  box-shadow: none !important;
}

.card-img-top-week {
  height: 100%;
  width: 100%;
  border-radius: 15px !important;
  object-fit: cover;
  cursor: pointer;
}

.card-img-top-weekend {
  aspect-ratio: 3/4;
  border-radius: 15px !important;
  object-fit: cover;
  height: 358px;
  cursor: pointer;
}

.card-img-top-personalized {
  height: 100%;
  width: 100%;
  border-radius: 0px !important;
  object-fit: cover !important;
  cursor: pointer;
  aspect-ratio: 3 / 4 !important;
  filter: brightness(0.9);
}

.bckping_img {
  padding: 10px;
  height: 290px;
  width: 328px;
  border-radius: 15px !important;
  object-fit: cover;
}

.for_Cbgimgw {
  height: 100% !important;
  /* width: 255px !important; */
  width: 100% !important;
     cursor: pointer;

  object-fit: cover;
}

.for_Cbgimgslider {
  height: 145px !important;
  width: 161px !important;
  border-radius: 15px !important;
  object-fit: cover;
  filter: brightness(0.9);
}

.carddiv {
  height: 480px !important;
  width: 350px !important;
  border-radius: 15px !important;
  object-fit: cover;
  cursor: pointer;
  background: white;
  box-shadow: 1.991px 1.991px 1.991px 0px rgba(0, 0, 0, 0.25);
  margin: 10px;
}

.card-img-personalized {
  height: 225px !important;
  /* width: auto !important; */
}

.test-prof {
  height: auto;
}

@media only screen and (max-width: 1200px) {
  .for_heading {
    font-size: 40px;
  }
}

.testparent {
  position: relative;
}

.testchild {
  position: absolute;
  height: 80%;

  top: 14%;
  width: 100%;
  translate: 0x 0y;
  text-align: center;
  padding: 20px 40px;
  /* text-wrap: ellipsis;
  white-space: none; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.testchildcontent {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: justify;
  color: rgba(0, 0, 0, 1);
}

.for_hrline {
  border: 2px solid rgba(255, 169, 35, 1);
  opacity: 1;
}

.perfect_trip {
  padding: 10px 10px;
  font-weight: 700;
  font-size: 32px;
  font-weight: 700;
  /* line-height: 44px; */
  letter-spacing: 0em;
  /* padding-top: 1%; */
}

.for_undercontent {
  margin-bottom: 14px;
  text-align: center;

  /* padding-left: 4%; */
}

.ver_slider {
  height: 178px !important;
  width: 161px !important;
  object-fit: cover !important;
  padding: 0px !important;
  filter: brightness(0.8);
}

.ver_sliderdiv {
  object-fit: cover !important;
}

.weekend {
  font-size: 200%;
  font-weight: 700;
  /* line-height: 48px; */
  letter-spacing: 0em;
  text-align: center !important;
  margin: auto;
  cursor: pointer;
  color: rgba(220, 27, 94, 1);
}

.backgroundimg {
  background-image: url(/src/assets/images/background.png);
  /* height: 100vh; */
  padding-top: 7rem;
  padding-bottom: 5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  background-repeat: no-repeat;
  /* width: 100%; */
  margin: 0;
  height: 700px;
  background-size: cover;
  /* background-position:center; */
}

.backgroundcontentunder {
  background: rgba(255, 234, 201, 0.5);
  width: 50%;
  text-align: center !important;
  margin: auto;
  padding: 3% 4% 5% 4%;
}

.for_h3line {
  font-size: 255%;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(220, 27, 94, 1);
}

.didnothr {
  width: 100% !important;
  border: 3px solid rgba(255, 169, 35, 1);
  opacity: 1;
}

.for_here {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5%;
}

.connectbtn {
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  background: rgba(255, 169, 35, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 43px;
  padding: 3% 11% 3% 11%;
  margin-top: 10%;
}

.text-between-lines {
  display: inline-block;
  background-color: white;
  padding: 0 10px;
  position: relative;
  top: -12px;
}

.hrrrrr {
  border: none;
  border-top: 2px solid #333;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.formd {
  position: relative;
}

@media (max-width: 768px) {
  .for_heading {
    font-size: 30px !important;
  }
  .yellogirl {
    margin-top: 50% !important;
  }

  .pding_2 {
    padding-top: 15px !important;
  }

  .mainbox {
    width: auto;

    height: auto;
  }

  .for_button {
    padding: 4px 15px;
  }

  .for_cards_featured {
    height: 209px !important;
    width: 330px !important;
  }

  .for_secondpersonalized {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    padding: 0;
  }

  .trekking_headers {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for_treaking {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    color: #dc1b5e;
  }

  .for_treaking2 {
    margin-left: 5px;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.03em;
    line-height: 30px;
    color: #000000;
    text-align: left;
  }

  .for_treakingfeature {
    margin-top: 0%;
    font-size: 26px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
  }

  .travel_content {
    color: #888888;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    padding: 25px 0px 0px 25px;
  }

  .show-mobile-view {
    display: flex !important;
    justify-content: center;
  }

  .none-mobile-view {
    display: none;
  }
}

.show-mobile-view {
  display: none;
}
@media (max-width: 320px) {
  .for_select {
    border: 1.5px solid #ffcb7a;
    margin: auto;
    width: 88%;
    border-radius: 9px;
    padding: 6px !important;
    font-size: 10px !important;
  }
}

@media (max-width: 480px) {
  .testchildcontent {
    font-size: 16px;
    overflow: scroll;
  }

  .weekend {
    font-size: 25px !important;
  }

  .weekendss_trip {
    font-size: 25px !important;
  }

  .for_content2 {
    padding: 0% !important;
  }

  .international-departure-section {
    margin-top: 4% !important;
    margin-bottom: 4rem !important;
  }

  .for_mobile_viewswiper {
    margin-top: 4% !important;
  }

  .test_text {
    font-size: 25px !important;
    font-weight: 600;
    color: #dc1b5e;
  }

  .for_aboutoffer {
    width: 100%;
    text-align: justify;
    color: rgba(130, 130, 130, 1);
    font-size: 16px !important;
    font-weight: 400;
    line-height: 22px;
    text-align: justified;
    word-spacing: -0.1em;
  }

  .weekend-section {
    margin-top: 0% !important;
  }

  .backpakin-btan-divi {
    width: 78%;
    background-color: #ffa923;
    border-radius: 32px;
    border: none;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-top: 14px;
    padding-top: 4px;
  }

  .for_btn_enquiry {
    font-size: 11px;
    line-height: 19px;
    padding: 6px 12px 6px 12px;
    border: none;
  }

  .tips_uper {
    font-size: 16px;
  }

  .card-img-top-weekend {
    border-radius: 15px !important;
    object-fit: cover;
    height: 232px;
  }

  .small-weekend {
    margin-top: 1% !important;
  }

  .treacking-experience {
    margin-top: -13% !important;
  }

  .v_inner_txt2 {
    font-size: 22px;
  }

  .for_weekandcontent {
    bottom: 44px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    left: 16px;
    cursor: pointer;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: left;
  }

  .chooseus {
    font-size: 25px !important;
    text-align: center !important;
    padding-bottom: 6%;
    color: #dc1b5e;
  }

  .ChildUdraw {
    height: 100%;
    width: 100%;
    padding: 10px;
    min-height: 340px !important;
  }

  .for_viewmore {
    text-align: justify;
    padding-top: 50% !important;
    padding-bottom: 20px !important;
  }

  .for_viewMore {
    min-inline-size: max-content;
    font-size: 20px !important;
    justify-items: center;
    position: relative;
    left: 50%;
    bottom: 20px;
  }

  .Under_img {
    border-radius: 560px 560px 0px 0px;
    height: 65%;
    object-fit: cover;
    width: 100%;
  }

  .backpacking-link {
    text-decoration: none;
  }

  .backpacking-text {
    text-decoration: none !important;
  }

  .Udraw2 {
    height: 65%;
    border: 1px solid black;
    border-radius: 560px 560px 0px 0px;
    margin-right: 0;
  }

  .for_small_devicegroup {
    background-color: white !important;
    font-size: 18px !important;
    padding: 0px !important;
  }

  .for_grouptrip {
    font-size: 25px !important;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
  }

  .for_personalizedtrips {
    font-size: 29px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    margin-top: 2% !important;
  }

  .personalized-link {
    text-decoration: none !important;
    font-size: 25px !important;
  }

  .Stop_expoloring {
    padding-top: 3% !important;
    padding-bottom: 3% !important;
    background-color: #ffeac9;
  }

  .for_packbutton {
    font-size: 11px !important;
  }

  .for_package {
    font-weight: 600;
    line-height: 24px !important;
    letter-spacing: 0em;
    font-size: 16px !important;
    padding-left: 28px;
    padding-top: 16%;
  }

  ._hrr {
    font-size: 17px;
    font-weight: 700;
  }

  ._hrprice {
    font-size: 17px;
    font-weight: 700;
  }

  .for_group_tripcard {
    margin-top: 1% !important;
  }

  .delhitrip {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .night {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .txt-1 {
    font-size: 18px;
  }

  .forhovereffect {
    font-size: 22px !important;
  }

  .for_departure {
    margin-left: 0px !important;
    text-align: justify;
    font-size: 22px !important;
  }

  .for_treakingfeature {
    margin-top: 0%;
    font-size: 23px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
  }

  .for_content {
    font-size: 22px !important;
    line-height: 28px;
    text-align: center;
    margin-top: 2% !important;
  }

  .card-image-find-tour {
    padding: 2% !important;
  }

  .for_upcoming_trips {
    margin-top: 2% !important;
  }

  .for_content_inner2 {
    border-radius: 15px;
    position: absolute;
    bottom: 0px;
    color: white;
    height: 20%;
    width: 100%;
    padding: 4px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  }

  .txt-2 {
    font-size: 16px;
  }

  .test-prof {
    height: 50px;
  }

  .testchild {
    padding: 10px 25px;
  }

  .for_backimgparent {
    scale: 0.9;
    margin-top: 0px !important;
  }

  .Europe_trip {
    position: absolute;
    color: white;
    font-size: 19px;
    color: rgba(255, 255, 255, 1);
  }

  .for_button {
    padding: 4px 22px !important;
    margin: 10px;
  }

  .for_firstcontent {
    font-size: 11px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #dc1b5e;
  }

  .for_connectbtn {
    font-size: 12px;
    line-height: 13px;
    padding: 5px 20px 3px 17px;
  }

  .connectbtn {
    padding: 3% 15% !important;
  }

  .for_box_content_child {
    position: absolute;
    text-align: center;
    background: white;
    padding: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: pink;
    width: 48%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .for-options {
    font-size: 13px !important;
  }

  .perfect_trip {
    padding: 10px 10px;
    font-weight: 700;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0em;
  }

  .for_misery {
    font-size: 11px;
    font-weight: 600;
    line-height: 9px;
  }

  .for_select {
    border: 1.5px solid #ffcb7a;
    margin: auto;
    width: 88%;
    border-radius: 9px;
    padding: 6px !important;
    font-size: 12px;
  }

  .pinkbg {
    margin-top: 2%;
    height: 751px;
    width: 1105px;
    height: 751px;
    flex-shrink: 0;
  }

  .for_secondpersonalized {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    padding: 0;
  }

  .backgroundcontentunder {
    width: 98%;
    margin: auto;
    padding: 8% 8% 13% 8%;
    background: rgba(255, 234, 201, 0.6) !important;
  }

  .for_cards_personalized {
    height: 100% !important;
    border-radius: 15px !important;
  }

  .for_h3line {
    font-size: 200%;
  }

  .for_places {
    font-size: 175%;
  }

  .weekend {
    font-size: 237%;
    width: 100%;
  }

  .find_tourbtn {
    margin-bottom: 8%;
    font-size: 13px;
  }

  .for-featured-tours {
    margin-top: 1% !important;
  }

  .formd {
    margin-top: 7%;
  }

  .for_pbb1 {
    padding-bottom: 0px !important;
  }

  .for_cityname {
    font-size: 138% !important;
  }

  .for_treaking {
    font-size: 26px !important;
  }

  .small-international {
    padding: 2% !important;
  }

  .card-img-top-international {
    height: 215px !important;
    border-radius: 15px !important;
    object-fit: cover !important;
    cursor: pointer;
  }

  .for_treaking2 {
    font-size: 26px !important;
  }

  .treacking-experiences {
    margin-top: 0% !important;
  }
}

@media (max-width: 768px) {
  .pinkbg {
    width: auto;

    height: 751px;
    z-index: -1;
    position: relative;
  }

  .ChildUdraw {
    bottom: 18px;
    left: 18px;
    height: 100%;
    width: 100%;
    padding: 10px;
    min-height: 376px;
  }
}

.delhitrip {
  font-size: 23px;
  font-weight: 600;
}

.maincard {
  display: flex;
  flex-direction: row;
  position: relative;

  bottom: 2%;
  left: -1%;
}
@media (max-width: 850px) {
  .yellogirl {
    margin-top: 50% !important;
  }
  .for_tab {
    margin-top: 1% !important;
  }
  .card-img-top-weekend {
    aspect-ratio: 3 / 4;
    width: 98%;
    height: 100%;
    border-radius: 15px !important;
    object-fit: cover !important;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .back {
    padding: 0 5%;
    height: auto;
  }
  .maincard {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    position: relative;
    top: 65%;
  }

  .delhitrip {
    color: #000;

    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .night {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .rupee {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .back {
    background-image: url("../../assets/images/backimg.png");
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 108vh;
    margin-top: 2%;
  }

  .backheading,
  .trip {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .backheading {
    color: #dc1b5e;
    position: relative;
    bottom: 8%;
    left: -20%;
  }

  .trip {
    color: #000;
  }

  .smily {
    max-width: 100%;
    right: 0;
  }

  .smildiv {
    max-width: 100%;
    left: 0;
  }

  .for_box_content {
    padding: 20px;
    left: 10px;
  }

  .swiper-container {
    width: 100%;
  }

  .for_boxcontent {
    font-size: 12px;
    padding: 10px 15px 12px 10px;
  }

  .for_boxcontent2 {
    font-size: 12px;
    padding: 10px 15px 12px 10px;
  }

  .chooseus {
    font-size: 20px;
  }

  .for_box_content_child {
    position: absolute;
    bottom: 100px;
    text-align: center;
    background: white;
    padding: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: pink;
    width: 48%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .childunderimg {
    position: absolute;
    bottom: 52px;
    left: 130px;
  }

  .for_cityname {
    font-size: 29px;
  }

  .for_content {
    font-size: 24px;
    line-height: 28px;
  }

  .for_content2 {
    line-height: 21.78px;
    font-size: 14px;
  }

  .for_backimgparent {
    scale: 0.9;
    margin-top: 309px;
  }

  .for_backimgchild {
    left: -32px;
    scale: 0.9;
    top: 9px;
  }

  .ParentUdraw {
    position: relative;
  }

  .for_heading {
    font-size: 45px;
  }

  .for_first_about {
    font-size: 14px;
  }

  .for_find_btn {
    font-size: 14px;
    padding: 8px;
  }

  .backheading,
  .trip {
    font-size: 20px;
    width: 50%;
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  .delhitrip {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1295px) {
  .finalWidth {
    width: 1295px;
  }

  .diva,
  .divc {
    flex: 1;
  }

  .divc {
    background-color: rgb(255, 234, 201);
  }

  .finalWidth2 {
    width: 1295px;
    background: white !important;
    position: relative;
    /* z-index: 2; */
    margin-top: -56px;
    /* margin-bottom: 50px; */
    padding: 0 !important;
  }

  .divc2 {
    flex: 1;
    background-color: white !important;
  }

  .parent {
    box-sizing: border-box;
    display: flex;
  }

  .parent2 {
    box-sizing: border-box;
    display: flex;
  }
}

.swiper_ver_sliderr .swiper-button-prev {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffa923;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 256px;
  transform: rotate(90deg);
  top: 236px;
}

.swiper_ver_sliderr .swiper-button-next {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffa923;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 11px;
  transform: rotate(90deg);
  top: 330px;
}

@media only screen and (max-width: 1400px) {
  .swiper_ver_sliderr .swiper-button-prev {
    left: 220px;
  }

  .swiper_ver_sliderr .swiper-button-next {
    left: 220px;
  }
}

@media only screen and (max-width: 1200px) {
  .swiper_ver_sliderr .swiper-button-prev {
    left: 168px;
  }

  .swiper_ver_sliderr .swiper-button-next {
    left: 168px;
  }
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 24px;
}

.weekendss_trip {
  color: black;
}

@media only screen and (max-width: 992px) {
  .ver_swiper {
    height: 250px !important;
    width: 100%;
  }

  .test_text {
    font-size: 30px;
    font-weight: 600;
    color: #dc1b5e;
  }

  .backgroundimg {
    background-image: url(/src/assets/images/background.png);

    padding-top: 2rem;
    padding-bottom: 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    background-repeat: no-repeat;

    margin: 0;
    height: 350px;
    background-size: cover;
  }

  .for_h3line {
    font-size: 25px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(220, 27, 94, 1);
  }

  .didnothr {
    width: 100% !important;
    border: 2px solid rgba(255, 169, 35, 1);
    opacity: 1;
  }

  .for_here {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 5%;
  }

  .connectbtn {
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(255, 169, 35, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 43px;
    padding: 3% 11% 3% 11%;
    margin-top: 8%;
  }

  .for_grouptrip {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
  }

  .Stop_expoloring {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: black;
  }

  .weekend {
    font-size: 30px;
    font-weight: 700;
    /* line-height: 30px; */
    letter-spacing: 0em;

    text-align: center !important;

    color: rgba(220, 27, 94, 1);
  }

  .weekendss_trip {
    font-size: 25px !important;
    color: black;
    text-align: center;
  }

  .backpacking-text {
    font-size: 19px;
    line-height: 30px;
    font-weight: 700;
    color: #dc1b5e;
    text-decoration: underline;
    display: flex;
    margin: 20px;
    text-decoration-color: black;
  }

  .backpacking-trip-text {
    font-size: 19px;
    line-height: 30px;
    font-weight: 700;
    color: black;

    margin: 0px 5px;
  }

  .exploremore {
    text-align: center;
    font-size: 26px;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 60px;
  }

  .for_departure {
    margin-left: 10px;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.05em;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: 95%;
  }

  .for_places {
    font-size: 20px !important;
  }

  .for_viewMore {
    text-align: center;
    padding-top: 10%;
    text-align: justify;
    background-color: #ffa923;
    color: white;
    border-radius: 15.69px;
    padding: 10px 20px 10px 20px;
    border-radius: 13.69px;
    border: none;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
  }
}

@media only screen and (max-width: 992px) {
  .swiper_ver_sliderr .swiper-button-prev {
    display: none !important;
    left: 0;
    top: 50%;
  }

  .swiper_ver_sliderr .swiper-button-next {
    display: none !important;
    right: 10;
    top: 50%;
  }

  .swiper_ver_sliderr .swiper-pagination {
    display: block !important;
  }
}

.swiper_ver_sliderr .swiper-pagination {
  display: none;
}

.ver_swiper {
  height: 580px;
}

@media only screen and (max-width: 992px) {
  .backpaking_box {
    height: 560px;
  }

  .backpacking-pink {
    height: 530px;
    width: 98%;
  }

  .backpacking-light-yellow {
    height: 530px;
    width: 82%;
  }

  .carddiv {
    height: 420px !important;
    width: 300px !important;
  }
}

@media only screen and (max-width: 460px) {
  .swal2-container .swal2-popup {
    width: 15em !important;
    font-size: 14px;
    max-width: 100%;
    padding: 0 0 1.25em;
    border: none;
    border-radius: 5px;
  }
  .ygirl {
    margin-top: -50% !important;
  }

  .swal2-title {
    font-size: 1.1rem !important;
  }

  .backpaking_box {
    height: 540px;
  }

  .backpacking-pink {
    height: 520px;
    width: 130%;
  }

  .backpacking-light-yellow {
    height: 520px;
    width: 92%;
  }

  .carddiv {
    height: 380px !important;
    width: 240px !important;
  }
}
