.for_card {
  border: none;
  box-shadow: 0px 0px 10px 1px #0000004d;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
}

.pink-text {
  color: #dc1b5e;
}

.black-text {
  color: black;
}
.btnCreate {
  border: 1px solid orange;
  background-color: orange;
  color: #fff;
}
.for_booknow {
  border-radius: 31.85px;
  padding: 2px 13px 2px 13px;
  border: none;
  color: white;
  background: #ffa923;
}
.for_backpacking {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
}
.for_lorem {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;
}
.startrate {
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #dc1b5e;
  cursor: pointer;
}


@media (max-width: 768px) {
  .for_visitdays {
    bottom: 111px;
    padding: 6px 10px 2px 10px;
    left: 5px;
    opacity: 0.7;
  }
  .for_lorem {
    font-size: 12px;
  }
  .startrate {
    font-size: 12px;
  }
  .for_booknow {
    border-radius: 31.85px;
    border: none;
    font-size: 13px;
  }
  .for_h5moonsun {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .for_h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    color: #ffffff;
    display: flex;
    align-items: center;
    filter: brightness(3);
  }
  .for_visitdays {
    bottom: 78%;
    padding: 6px 17px 0px 7px;
    left: 0px;
    opacity: 1;
  }
  .for_backpacking {
    font-size: 30px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    padding-top: 1% !important;
  }
  .for_card{
    margin-top: 2%;
  }
}
.for_card_week{
  border: none;
  box-shadow: 0px 0px 10px 1px #0000004d;
  /* position: relative; */
  border-radius: 10px;
}
.card-img3,.card-img-top-week2 {
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
  height: 225px !important;
  width: auto !important;
  position: relative !important;
}
.dayandnightw{
  position: absolute !important;
  right: 4%;
  top: 180px;
  /* bottom: 38%; */
  background: #000000cc;
  color: #ffffff;
  padding: 5px 7px 5px 9px;
  border-radius: 5px;
}

@media(max-width: 480px){

  .for_backpacking {
         font-size: 25px !important;
  } 
}