.btnstyl {
  padding: 10px 50px 10px 50px;
  font-size: large;
  font-weight: 500;
  background-color: #ffa923;
  color: #f5f5f5;
  border-radius: 25px;
}
.m_heading {
  font-size: x-large;
  text-align: -webkit-center;
  background: #577c60;
  color: #fff;
}
.travellar-form-size {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 0px;
  background: #f5f5f5;
  box-shadow: 0px 0px 1px 1px rgba(245, 245, 245, 245);
  margin-top: 25px;
  padding: 1rem;
}

.traveller-H {
  color: #dc1b5e;
  text-align: center;
  font-size: 153%;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}
.traveller-submit {
  margin-top: 3%;
  margin-bottom: 2%;
}
.travellar-input {
  border: 1px solid #ffa923;
  text-align: start;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000000;
}
.travellar-input::placeholder {
  color: #bbb !important;
}
.travellar-input-arrow-back {
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 0px 5px 5px 0px;
  background: #ffa923;
}
.travellar-dropdown-icon {
  fill: #ffa923;
}

.travellar-lable {
  color: #000;
  display: flex;
  justify-content: start;
  text-align: start;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
  font-family: "inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}
.travellar-submit {
  width: 100%;

  flex-shrink: 0;
  border-radius: 12px;

  margin-top: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.travellar-submit-text {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  background: #ffa923;

  border-radius: 12px;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.errow-travellar {
  border: 1px solid #ffa923;
  background-color: #ffa923;
  color: #fff;
}
.mainerrow {
  border: 1px solid #ffa923;
  border-radius: 5px;
}
.travellar-input2 {
  border: none;
}

.accordion-style {
  --bs-accordion-bg: #f5f5f5;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='4' height='4'%3e%3ccircle cx='12' cy='12' r='12' fill='%23FFA923'/%3e%3cpath fill='%23FFFFFF' fill-rule='evenodd' d='M16.59 9.59L12 14.17 7.41 9.59 6 11l6 6 6-6z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='4' height='4'%3e%3ccircle cx='12' cy='12' r='12' fill='%23FFA923'/%3e%3cpath fill='%23FFFFFF' fill-rule='evenodd' d='M16.59 9.59L12 14.17 7.41 9.59 6 11l6 6 6-6z'/%3e%3c/svg%3e");
}
.trvler .accordion-button:not(.collapsed) {
  color: #dc1b5e !important;
  background-color: #f5f5f5 !important;
  box-shadow: none;
}
.c-b {
  color: #000 !important;
}
@media (max-width: 1024px) {
  .travellar-lable {
    font-size: 0.77rem;
  }

  .travellar-form-size {
    width: 85%;
  }
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff !important;
  opacity: 1;
}

.file-upload {
  border-radius: 5px;
  font-size: 18px;
  border: 1px solid #ffa923;
  padding: 0px 0px 0px 5px;
  height: 40px;
  color: #bbbbbb;
  background-color: white;
  cursor: pointer;
}
.file-upload input[type="file"] {
  display: none;
}
.upload-icon {
  padding: 10px;
  color: #fff;
  background-color: #ffa923;
}
