.background1 {
  background-image: url(/src/assets/images/successfulbg.jpg.png);

  height: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  background-repeat: no-repeat;
  width: auto;
  margin: 0 auto;
  background-size: cover;
}
/* .toast{
  
    width: max-content;
    padding: revert-layer;
    margin-top: 7.6em;
} */

@media (max-width: 400px) {
  .background1 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}

.wel {
  color: #fff;
  padding-top: 2rem;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  padding-bottom: 0rem;
}

.logincontent {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2rem;
}

.mainform {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .mainform {
    flex-direction: column;
  }
}

.bigdivform {
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 540px;
  height: auto;
  max-height: 552px;
  flex-shrink: 0;
  padding: 2rem;

  /* align-items: center;
  justify-content: center; */

  flex-wrap: nowrap;

  border-radius: 10px;
  background: rgba(0, 0, 0, 0.6);
  position: relative;
}

@media (max-width: 768px) {
  .bigdivform {
    max-width: 100%;
    height: auto;
    background-size: contain;
  }
}

.sendotp {
  color: #fff !important;
  text-align: center;
  background-color: #dc1b5e !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  border-radius: 6.766px !important;
  border: 1px solid #dc1b5e !important;
  /* background-color: transparent; */
  text-decoration: none !important;
  padding: 2px !important;
  text-decoration: none !important;
}

@media (max-width: 380px) {
  .sendotp {
    top: -0.2rem;
    position: relative;
    float: none;
    margin-right: 0rem;
    height: auto;
    width: auto;
    font-size: 10px !important;
  }
}

.sendlable {
  float: right;
  position: relative;
  top: 2.5rem;
}
@media (max-width: 320px) {
  .sendlable {
    position: relative;
    width: auto;
  }
}

.your-input-class {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #e0e0e0 !important;
  border-radius: 0 !important ;
  color: #e0e0e0 !important;
}

.your-input-class:focus,
.your-input-class:active {
  border-bottom: 2px solid #e9ebf0 !important;
  /* box-shadow: 0 0 5px rgba(234, 237, 243, 0.5);  */
  box-shadow: none;
}

.your-input-class::placeholder {
  color: #e0e0e0 !important;
}

@media only screen and (max-width: 320px) {
  .your-input-class {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 850px) {
    .verifybtn2 {
    border-radius: 15px !important;
    background: #dc1b5e !important;
    flex-shrink: 0;
    padding: 2% 30% !important;
    color: #fff !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
    text-decoration: none !important;
  }
}

.verifybtn2 {
  border-radius: 15px !important;
  background: #dc1b5e !important;

  flex-shrink: 0;
  padding: 2% 38% !important;
  color: #fff !important;

  font-size: 18px ;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none !important;
}

@media (max-width: 600px) {
  .verifybtn {
    font-size: 16px;

    height: auto;
    width: auto;
  }
  .logincontent {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2rem;
  }
  .background1 {
    background-image: url(/src/assets/images/successfulbg.jpg.png);
    height: 100%;
    /* padding-top: 5rem; */
    /* padding-bottom: 5rem; */
    align-items: center;
    justify-content: center;
    display: block;
    background-repeat: no-repeat;
    width: auto;
    margin: 0 auto;
    background-size: cover;
    padding: 0 !important;
  }
  .bigdivform {
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
      -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100vw;
    height: 90vh;
    max-width: none;
    max-height: none;
    flex-shrink: 0;
    padding: 2rem;
    flex-wrap: nowrap;
    border-radius: 0 !important;
    background: rgba(0, 0, 0, 0.6);
    position: relative;
  }

  .verifybtn2 {
    border-radius: 15px !important;
    background: #dc1b5e !important;
    flex-shrink: 0;
    padding: 2% 30% !important;
    color: #fff !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none !important;
  }
  .loginconten {
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }
  .wel {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    padding-top: 5rem;
  }
}

.lastpara {
  color: #fff;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 2rem;
}

.lastlink {
  color: #ffa923;

  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
.lastlink:hover {
  color: #dc1b5e;
}
.sendlable2 {
  float: right;
  position: relative;
  top: 2.5rem;
}

.resend {
  margin-top: -2rem;
}

.colored-toast.swal2-icon-success {
  background-color: rgb(22, 153, 44) !important;
}

.colored-toast.swal2-icon-error {
  background-color: #db4343 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
  font-size: 15px !important;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}

.swal2-loading {
  animation: spin 2s linear infinite;
}

.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
  font-size: 14px !important;
}

div:where(.swal2-container) div:where(.swal2-loader) {
  border-color: #ffc409 rgba(0, 0, 0, 0) #ffc409 rgba(0, 0, 0, 0) !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  width: 24em !important;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
}
