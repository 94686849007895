.for_card {
  border: none;
  box-shadow: 0px 0px 10px 1px #0000004d;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
}
.smallhrline {
  display: none;
}

.pink-text {
  color: #dc1b5e;
}

.black-text {
  color: black;
}
.btnCreate {
  border: 1px solid orange;
  background-color: orange;
  color: #fff;
}
.cardimg,
.cardimg-top {
  border-top-right-radius: 1;
  height: 225px !important;
  width: auto !important;

}
.loremforfeatured {
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.dayandnight {
  position: absolute !important;
  right: 4%;
  top: 180px;
  /* bottom: 38%; */
  background: #000000cc;
  color: #ffffff;
  padding: 5px 7px 5px 9px;
  border-radius: 5px;
}
@media (max-width: 375px) {
  .dayandnight {
    position: absolute !important;
    right: 4%;
     bottom: 36% !important; 
    background: #000000cc;
    color: #ffffff;
    padding: 5px 7px 5px 9px;
    border-radius: 5px;
  }
}
@media( max-width: 480px){
  .dayandnight{
    position: absolute !important;
    right: 4%;
     bottom: 36% !important; 
     background: #000000cc;
    color: #ffffff;
    padding: 5px 7px 5px 9px;
    border-radius: 5px;
  }
}

.for_booknow {
  border-radius: 31.85px;
  padding: 2px 13px 2px 13px;
  border: none;
  color: white;
  background: #ffa923;
}
.for_backpacking {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
}
.for_lorem {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;
}
.startrate {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  cursor: pointer;
}
.for_visitdays {
  position: absolute;
  top: 8%;
  color: #000000;
  background: #FFA923;
  padding: 3px 12px 0px 5px;
  left: 0%;
  opacity: 1;
  cursor: pointer;
  border-radius: 0 5px 5px 0px;
}
.card-img2, .card-img-top-inter2{
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
  height: 225px !important;
  width: auto !important;
  position: relative !important;
}
.for_card_inter{
  border: none;
  box-shadow: 0px 0px 10px 1px #0000004d;
  /* position: relative; */
  border-radius: 10px;
}
.for_h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #ffffff;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  
}

@media (max-width: 768px) {
  .for_visitdays {
    bottom: auto;
    padding: 6px 10px 2px 10px;
    left: 0px;
    opacity: 0.7;
    color: #000000;
  }
  .for_lorem {
    font-size: 12px;
  }
  .startrate {
    font-size: 12px;
  }
  .for_booknow {
    border-radius: 31.85px;
    border: none;
    font-size: 13px;
  }
  .for_h5moonsun {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .smallhrline {
    border: 3px solid #dc1b5e;
    opacity: 1;
    display: block;
  }
  .for_backpacking {
    font-size: 23px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .for_lorem {
    font-size: 18px !important;
  }
  .for_h5moonsun {
    font-size: 22px !important;
  }
  .startrate {
    font-size: 18px !important;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    padding-top: 3%;
  }
  .for_booknow {
    border-radius: 31.85px;
    border: none;
    font-size: 19px !important;
    padding: 1px 20px 1px 20px !important;
  }
  .for_h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    color: #fff;
  }
  .for_Goclock {
    font-size: 18px;
  }
  .golockspace {
    padding: 0px 7px 0px 6px;
  }
  .gap{
    padding-top: 0px !important;
    font-size: 25px !important;
  }
  .endg{
    margin-bottom: -50px !important;
  }
}
/* @media (max-width: 480px) {
  .dayandnight {
    position: absolute;
    right: 4%;
    bottom: 32% !important;
  }
}
@media (max-width: 375px) {
  .dayandnight {
    position: absolute;
    right: 4%;
    bottom: 42% !important;
    background: #000000cc;
    color: #ffffff;
    padding: 5px 7px 5px 9px;
    border-radius: 5px;
  }
} */
