.col {
  position: relative;
}

.forcard {
  border: none;
  width: 100%;
  position: relative;
  border-radius: 40px 40px 40px 40px;
  background-color: white;
}

.bimgrad {
  filter: contrast(90%) brightness(92%);
  border-top-right-radius: 1 !important;
}

.blog-page-img-text {
  font-family: "inter";
  font-size: 18px;
  font-weight: 700;
  text-align: justify;
  padding-left: 3%;
  padding-right: 3%;
  letter-spacing: 0.9px;
}

.blog-page-img-text2 {
  text-align: start;
  padding-left: 8%;
  padding-right: 3%;
  letter-spacing: 0.9px;
}

.for_handleserach {

  cursor: pointer;
}

.blog-page-img-text3 {
  text-align: start;
  padding-left: 10%;
  padding-right: 10%;
}

@media (min-width: 1400px) {
  .blog-page-img-text {
    text-align: justify;
    padding-left: 8%;
    padding-right: 18%;
    letter-spacing: 0.9px;
  }

  .blog-page-img-text2 {
    text-align: start;
    padding-left: 12%;
    padding-right: 13%;
    letter-spacing: 0.9px;
  }

  .blog-page-img-text3 {
    text-align: start;
    padding-left: 10%;
    padding-right: 16%;
  }
}

.text-overlay {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.text-overlay h1 {
  color: white;
  font-weight: bold;
  font-size: 50px;
  margin-top: 250px;
}

.horizontal-h-line {
  border: 2px solid #ffa923;
  background: #ffa923;
  margin: 20px 0;
}

.overlay-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: -webkit-linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5));
  text-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 10px 10px 10px 10px;
  border: 0px;
}

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .text-overlay h1 {
    font-size: 14px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 568px) and (max-width: 992px) {
  .text-overlay h1 {
    font-size: 30px;
    margin-top: 110px;
  }
}

@media (max-width: 600px) {
  .for_mediahide {
    display: none;
  }

  .for_alltext {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 0% !important;
  }

  .for_alltext1 {
    text-align: left;
    padding-left: 5%;
    padding-right: 1%;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
  }

  .forspaceboottm {
    margin-bottom: 6% !important;
  }
}

.card-img-blog,
.card-imgblog {
  border-radius: 10px 10px 10px 10px;
  height: 271px !important;
  width: auto !important;
  position: relative !important;
  object-fit: cover;
}