.booking {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  border-radius: 0%;
  text-align: start;
}
.text-div {
  display: flex;
  justify-content: center;
  align-items: start;
}
.for-partial-payment-color {
  background-color: #71c6e7;
}
.listtext {
  border: none !important;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.data-text-div {
  display: flex;
  justify-content: space-around;
  text-align: start;
}
.view-text-div {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.booking-text {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: nowrap;
  text-align: start;
}
.view-more-text {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: nowrap;
  text-align: start;
  cursor: pointer;
  text-decoration: underline;
}

.headcard {
  width: 100%;
  border: none;
}





.viewMore {
  text-decoration: underline;
}
@media (max-width: 600px) {
  .hero {
    padding: 10px;
  }
  .date {
    font-size: 17.5px !important;
    font-weight: 400;
  }
  .booking-text {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-wrap: nowrap;
    text-align: start;
  }
  .date {
    font-size: 15px !important;
    font-weight: 400;
    padding-left: 23px;
  }
  .date2 {
    padding-right: 40px;
  }
}
@media (max-width: 768px) {
  .date {
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 480px) {
  .for-customclass-forview {
    font-size: 85% !important;
  }
  .bookicon {
    width: 89% !important;
    padding: 5px;
    height: auto;
  }
  
  
  .custom-for-booking {
    margin-top: 2% !important;
  }
  .listtext {
    font-size: 18px !important;
  }
}
@media (max-width: 375px) {
  .listtext {
    font-size: 16px !important;
  }

}

@media (max-width: 320px) {
 
  .for-customclass-forview {
    font-size: 75% !important;
  }
  .listtext {
    font-size: 14px !important;
  }
}
