.select-wrapper {
  position: relative;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust this value to position the icon */
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the icon doesn't interfere with select functionality */
}




.vertical-line {
  border-right: 1px solid #ddd;
}

.profile-image {
  border-radius: 50%;

  margin-right: 10px;

  margin-bottom: 25px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border: 1px solid #dc1b5e;
  background: #fff;
  border-radius: 22px;
  color: #dc1b5e !important;
}
.non_editeable {
  color: #666363 !important;
  cursor: not-allowed;
  /* border: none !important; */
  /* font-weight: bold !important; */
}

.edit-mode {
  background-color: #ffffff !important;
  color: #000 !important;
}

.form-control.place {
  color: black; /* Set text color to black */
}

@media only screen and (max-width: 600px) {
  .text-right {
    text-align: left !important;
  }

  .d-mob {
    display: block !important;
  }
}

@media only screen and (min-width: 768px) {
  .fRight {
    float: right;
  }
}

.mt-4,
.my-4 {
  margin-top: 2.5rem;
}

.mt-5,
.my-5 {
  margin-top: -2rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.card-title {
  margin-bottom: 0rem;
}

.text-dark {
  color: black;
}

.colBlack {
  color: black;
  text-align: center;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.editbtn {
  color: #dc1b5e !important;
  float: right;
}

.mainc {
  display: flex;
  flex-direction: row;
  color: black;
  padding-left: 13%;
  position: absolute;
  top: 35%;
}

@media (max-width: 768px) {
  .mainc {
    flex-direction: column;

    padding-top: 5rem;
  }
}
@media (max-width: 320px) {
  .mainc {
    flex-direction: column;
    margin-top: 22rem;
  }
}
@media (max-width: 700px) {
  .mainc {
    flex-direction: column;
    margin-top: 15rem;
  }
}

.bigdivc {
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  width: auto;
  max-width: 825px;
  height: auto;
  flex-shrink: 0;
  padding: 40px;
  margin-bottom: 2%;
  display: flex;
  flex-wrap: nowrap;
  margin-right: 5%;
}

/* Add a media query for smaller screens */
@media (max-width: 600px) {
  .bigdivc {
    max-width: 100%;
    height: auto;
  }
}

#bg-gradient {
  background: -webkit-linear-gradient(#dc1b5e, #ffa923);

  color: white;

  padding: 20px;

  border-radius: 10px;

  position: relative;
}

@media only screen and (max-width: 768px) {
  #bg-gradient {
    position: relative;
    bottom: 20%;
  }
}

.list {
  text-align: center;
  margin-left: 15%;
  margin-top: 10%;
}

#coupnbtn {
  border-radius: 24.519px;
  border: 1.962px solid #dc1b5e;
  background-color: white;

  line-height: normal;
  /* margin-left: 1%; */
  text-align: center;

  color: #dc1b5e;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15%;
}

@media only screen and (max-width: 768px) {
  #coupnbtn {
    font-size: 16px;
    /* margin-right: 10%; */
    padding: 4%;
  }
}

.customerform {
  padding-top: 0;
}

.customer-border {
  border-bottom: 1px solid #beb3b3; /* Set the border width and color */
  width: 100%; /* Set the desired length */
  margin: 0 auto; /* Center the element */
}

.profile {
  color: #000;

  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.edit {
  color: #dc1b5e;
  font-family: Inter;
  font-size: 20.923px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  float: right;
  cursor: pointer;
}

.save {
  color: #dc1b5e;

  font-family: Inter;
  font-size: 20.923px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  float: right;
  cursor: pointer;
}

.lablename {
  color: #000;

  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.place {
  color: #c2c2c2;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4.847px;
  background: #ebebeb;
  /* box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25); */
  /* border: none; */
}
.place:focus,
.place:active {
  border: 2px solid #e9ebf0 !important;
  /* box-shadow: 0 0 5px rgba(234, 237, 243, 0.5);  */
  box-shadow: none;
  background: #ebebeb;
}
.profile-image {
  border-radius: 50%;
  margin-right: -5px;
  margin-bottom: 5px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border: 1px solid #dc1b5e;
  background: #fff;
  border-radius: 22px;
  color: #dc1b5e !important;
}

@media only screen and (max-width: 600px) {
  .text-right {
    text-align: left !important;
  }

  .d-mob {
    display: block !important;
  }
}

@media only screen and (min-width: 768px) {
  .fRight {
    float: right;
  }
}

.mt-4,
.my-4 {
  margin-top: 2.5rem;
}

.mt-5,
.my-5 {
  margin-top: -2rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}
.placeholderclass {
  border: none;
}

.card-title {
  margin-bottom: 0rem;
}

.text-dark {
  color: black;
}

.colBlack {
  color: black;
  text-align: center;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.editbtn {
  color: #dc1b5e !important;
  float: right;
}

.mainc {
  display: flex;
  flex-direction: row;
  color: black;
  padding-left: 13%;
  position: absolute;
  top: 35%;
}

@media (max-width: 768px) {
  .mainc {
    flex-direction: column;

    padding-top: 5rem;
  }
}
@media (max-width: 320px) {
  .mainc {
    flex-direction: column;
    margin-top: 22rem;
  }
}
@media (max-width: 700px) {
  .mainc {
    flex-direction: column;
    margin-top: 15rem;
  }
}

.bigdivc {
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  width: auto;
  max-width: 825px;
  height: auto;
  flex-shrink: 0;
  padding: 40px;
  margin-bottom: 2%;
  display: flex;
  flex-wrap: nowrap;
  margin-right: 5%;
}

/* Add a media query for smaller screens */
@media (max-width: 600px) {
  .bigdivc {
    max-width: 100%;
    height: auto;
  }
}

.lable {
  text-align: start;
  font-weight: 600;
}

#bg-gradient {
  background: -webkit-linear-gradient(to right, #dc1b5e, #ffa923);

  color: white;

  padding: 20px;

  border-radius: 10px;

  position: relative;
}

@media only screen and (max-width: 768px) {
  #bg-gradient {
    position: relative;
    bottom: 20%;
  }
}

.list {
  text-align: center;
  margin-left: 15%;
  margin-top: 10%;
}

#coupnbtn {
  border-radius: 24.519px;
  border: 1.962px solid #dc1b5e;
  background-color: white;

  line-height: normal;
  /* margin-left: 1%; */
  text-align: center;

  color: #dc1b5e;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15%;
}

@media only screen and (max-width: 768px) {
  #coupnbtn {
    font-size: 16px;
    /* margin-right: 10%; */
    padding: 4%;
  }
}

.customerform {
  padding-top: 0;
}

.customer-border {
  border-bottom: 1px solid #beb3b3; /* Set the border width and color */
  width: 100%; /* Set the desired length */
  margin: 0 auto; /* Center the element */
}

.profile {
  color: #000;

  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.edit {
  color: #dc1b5e;

  font-family: Inter;
  font-size: 20.923px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  float: right;
}

.lablename {
  color: #000;

  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.place {
  color: #c2c2c2;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 4.847px;
  background: #ebebeb;

  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}
