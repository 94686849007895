.accordion-button,
.accordion-collapse {
  background-color: #f5f5f5 !important;
}

.accordion-button:not(.collapsed) {
  background-color: #f5f5f5 !important;
}
 .forsmalldevicebttn {
    display: flex;
    gap: 10px;
  }
.accordion-item {
  background-color: white !important;
}

.btn-primary {
  --bs-btn-border-color: white;
}

.csty {
  color: #dc1b5e;
  font-weight: 700;
}

.lsty {
  font-size: 12px;
}
.expdate{
   font-size: 12px;
   font-weight: 700;
}
.form-label {
  font-size: 15px;
}

.round {
  border: 2px solid #fff;
  height: 50px;
  width: 50px;
  background-color: #00000040;
  border-radius: 50px;
}

.buttonstyl {
  font-weight: 700;
  color: #dc1b5e !important;
}

.custom_class_coupon {
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 6px 0px 5px 12px;
}

.btn-primary:hover {
  background: #ffa923;
}

.fa-heart:active {
  color: red !important;
}

.btn-primary {
  border-radius: 12px;
  background: #ffa923;
  color: #ffffff;
  --bs-btn-active-bg: #ffa923;
}

.for_select_book {
  border-radius: 10px;
  border: 1px solid #000;
  background: #f5f5f5;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, #dc1b5e, #dc1b5e);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.h6 {
  font-size: 0.75rem;
}

.d-inline-block {
  font-size: 0.75rem;
}

.tourDate,
.boardingPoint {
  color: #000;
  font-family: "inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.note {
  color: #ff0000;
  font-family: 13px;
  font-weight: 500;
}

.package {
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  height: 410px;
}

.room {
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  height: 200px;
}

.for_number_trip {
  background: none !important;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0px !important;
}

.for_plus {
  border-radius: 5px;
  padding: 0px 3px 0px 3px;
  border: 1px solid #ffa923;
}

.for_minus {
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
  border: none;
  background: #ffa923;
  color: white;
}

.forSelect {
  border-radius: 10px;
  border: 1px solid #000;
  background: #f5f5f5;
}

.for_tripimage {
  width: 100%;
}

.menu {
  display: none;
  transition: display 0.3s ease;
}

.menu-open {
  visibility: hidden;
}

.menu-open:checked ~ .menu {
  display: block;
}

.for_justify {
  text-align: justify;
}

.for_firsthead {
  color: #000;
  text-align: justify;
  font-size: 15.555px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
  font-family: "inter";
}

.Itinerary_images {
  width: 100%;
  height: 8rem;
  object-fit: cover;
  aspect-ratio: 5 / 3;
  border-radius: 9%;
  max-width: 100%;
}

.second_head {
  color: #505050;
  font-size: 15.555px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  text-align: justify;
  font-family: "inter";
}

.mainhead {
  text-align: justify;
}

.for_head {
  text-align: justify;
  color: #dc1b5e;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.4px;
}

.parentdiv {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.parentdiv2 {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  position: sticky;
  position: -webkit-sticky;
  position: sticky;
  top: 140px;
  margin-bottom: 2rem;
}

.couponCode {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  margin: 25px 0px;
  height: 35px;
  width: 100%;
  padding: 0px 20px;
}

.couponText {
  height: 35px;
  width: 50%;
  border-radius: 9.231px 0px 0px 9.231px;
  background-color: #dc1b5e;
  color: #fff;
  padding: 7px 5px;
  text-align: center;
  font-family: "inter";
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
}

.coupon {
  height: 35px;
  width: 50%;
  border-radius: 0px 9.231px 9.231px 0px;
  background: #f5f5f5;
  color: #000;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 2.323px;
  padding: 7px 5px;
  text-align: center;
  font-family: "inter";
  border: none;
  text-align: start;
}

.for_buttonMonth {
  border: 0.616px solid #ffa923;
  border-radius: 9.23px;
  padding: 2px 21px 2px 21px;
  background: white;
}

.for_buttonMonth.active {
  background: #ffa923;
  color: white;
}

.for_smallflex {
  display: flex;
  align-items: center !important;
}

.for_button2 {
  border: 2px solid #ffa923;
  border-radius: 9.23px;
  padding: 2px 21px 2px 21px;
  background: white;
  background: #ffa923;
  color: white;
}

.for_datescosting {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}

.for_box {
  background: #f5f5f5;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 9px 1px 10px;
}

.for_box2 {
  background: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 9px 1px 10px;
  color: #dc1b5e;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.for_box_content {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.for_highlight_content {
  color: #000;
  text-align: justify;
  font-size: 15.555px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #ffa923;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.heading_highlight {
  text-align: justify;
  color: #dc1b5e;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.nav-tabs .nav-link {
  border: none;
}

.less-icon-golaphoto {
  margin-top: -70px;
  background: white;
  border-radius: 100%;
  border: 1px solid white;
  padding: 1px 7px 4px 5px;
  cursor: pointer;
}

.btn-primary:hover {
  background: white;
  color: black;
}

.less-icon-gola-vdo {
  margin-top: -70px;
  background: white;
  border-radius: 100%;
  border: 1px solid white;
  padding: 1px 7px 4px 5px;
  cursor: pointer;
}

.highlight_div {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.for_note_div {
  text-align: justify;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin: 3% 0 !important;
}

.for-inclusion-text ul li {
  padding-bottom: 0% !important;
}

.tripdetails-enquriy {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px 1px;
  border-radius: 12px;
}

.for-enquriy {
  margin-top: 1% !important;
}

.Enquriy-text {
  padding-bottom: 3%;
  padding-top: 3%;
}

.for_note_content {
  color: #525252;
  text-align: justify;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "inter";
}

.wrapper_slider .swiper-wrapper {
  align-items: center !important;
}

@media only screen and (max-width: 769px) {
  .flexx {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup-container {
    position: fixed;
    bottom: 2% !important;
    right: 27% !important;
    z-index: 1000;
    border-radius: 2%;
  }
  .wrapper_slider .swiper-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 100px !important;
  }
}

.for_dot {
  font-size: 29px;
}

.highLights {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.for_booknow {
  padding: 8px 56px 8px 56px;
  border-radius: 12px;
  background: #ffa923;
  border: none;
  color: white;
}

.for_drop {
  margin: 20px 15px;
  font-size: 10px !important;
}
.for_dro2 {
  font-size: 14px;
  border: 1.5px solid #ffcb7a;
  margin: auto;

  border-radius: 9px;
  padding: 3px 20px 3px !important;

  white-space: nowrap; /* Prevents the text from wrapping into multiple lines */
  overflow: hidden; /* Hides any overflow */
  text-overflow: ellipsis; /* Adds ellipsis (...) if the text is too long to fit */
  width: 100%; /* Ensure the select box takes the full width of its container */
}

.react-tabs__tab-list {
  margin: 0 0 10px;
  padding: 0;
  border: 2px solid #ffa923 !important;
  border-radius: 50px;
}

.react-tabs__tab--selected {
  color: white !important;
  border-radius: 50px !important;
  background: #ffa923 !important;
  border: none !important;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 45px 10px;
  padding: 0;
}

.for_select_trip {
  margin: 0%;
  border: 1px solid #000;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  background: #ffa923;
  border: none !important;
  border-radius: 40px;
  padding: 9px 93px;
  color: white;
}

.nav-tabs {
  border: 2px solid #ffa923;
  border-radius: 50px;
  padding: 0;
}

.for_padding {
  /* padding: 30px; */
  /* margin-left: -22px; */
  padding: 0px;
}

nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 6px 76px;
}
.for_booknow {
  padding: 6px 13px 6px 13px;
  border-radius: 12px;
  background: #ffa923;
  border: none;
  color: white;
  width: 50%;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
}

.trip-btan-date-costing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.photocardchild {
  color: white;
  border-radius: 11px;
  position: absolute !important;
  top: 0;
  left: 18px;
  background: #ffa923;
  padding: 18px 126px 76px 29px;
}

.wtsp-img {
  margin-top: -123%;
  margin-left: 100%;
}

.photocard {
  position: relative;
  height: 250px;
  border-radius: 15px;
  object-fit: cover !important;
  width: 95%;
}

.icon-setup-left {
  position: absolute;
  right: 25%;
  bottom: 8%;
}

.icon-setup-right {
  position: absolute;
  right: 7%;
  bottom: 8%;
}

.xhd {
  width: 300px;
}

.videocard {
  position: relative;
  height: 220px;
  border-radius: 15px;
  object-fit: cover !important;
  width: 95%;
}

.videoCard {
  position: relative;
}

.for-photo-under {
  top: 0px;
  right: 5%;
  padding: 5% 39% 42% 51%;
  background: #ffa923;
  border-radius: 1% 0% 5% 5%;
  text-align: center;
}

.for-photo-under2 {
  background: #ffa923;
  border-radius: 1% 0% 5% 5%;
  text-align: center;
  border-radius: 1% 0% 5% 5%;
  padding: 5% 83% 40% 12%;
  position: absolute;
  left: 2%;
}

.photos-word {
  position: absolute;
  right: 30%;
  top: 16%;
  font-size: 30px;
  color: white;
}

.rectangle {
  height: 30px;
  width: 60px;
  background-color: #ffa923;
}

.svg_img {
  position: absolute;
  bottom: 0px;
  right: 30px;
}

.yt_child {
  position: absolute;
  bottom: 59px;
  left: 122px;
}

.yt {
  position: relative;
}

.for_btntab {
  color: black;
}

.for_btntab:hover {
  color: black;
}

.for_contentunder {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter";
  letter-spacing: 0em;
  color: #000000;
}

.for_inclusion {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  color: #dc1b5e;
}

.parentdiv3 {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 21px 49px 12px 23px;
}

.Exclusions_div {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 21px 49px 12px 23px;
  margin-bottom: 1rem;
}

.for_exclusioncontent {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

#mySelect {
  width: 85%;
  text-align: center;
  font-size: 166%;
  padding: 2% 0% 2% 0%;
}

.less-icon-gola {
  border-radius: 100%;
  border: 1px solid black;
  padding: 1px 7px 4px 7px;
}

.slider-icon-gol-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.Get-quote-btn {
  padding: 3% 21% 3% 24%;
}

.day {
  padding: 0 24px;
  font-size: 195%;
  color: white;
}

.for_selectdiv {
  padding: 2% 4% 0 4%;
}

.forsvg {
  border: 2px solid white;
  border-radius: 100px;
  padding: 6%;
  font-size: 127%;
  background-color: #02000040;
}

.forsvg2 {
  font-size: 69px;
}

.mainimg {
  height: 295px;
  border-radius: 15px;
  object-fit: cover !important;
  position: relative;
}

.bookNow {
  margin: 9px 0px;
  width: 185px;
  height: 38px;
  border: none;
  border-radius: 12px;
  background: #ffa923;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  font-family: "inter";
  line-height: normal;
}

.childmainimg {
  position: absolute;
  bottom: 0px;
  height: 25%;
  width: 100%;
  color: white;
  font-size: 18px !important;
  font-weight: 600;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  padding: 10px;
  text-align: -webkit-left;
}

.childmainimgblog {
  position: absolute;
  width: 100%;
  bottom: 0px;
  color: white;
  font-size: 18px !important;
  font-weight: 600;
  border-radius: 15px;
  height: 18%;
  text-align: -webkit-left;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  padding: 10px;
}

.detaiL-row {
  width: 33%;
}

.forsimilar {
  height: 295px;
  width: 255px;
  border-radius: 15px;
  object-fit: cover !important;
}

.forimgg {
  height: 255px;
  width: 255px;
  border-radius: 15px;
  object-fit: cover !important;
}

.chat_bx {
  width: 40px !important;
}

.swiper_2nd_photo {
  height: 250px;
  width: 300px !important;
  border-radius: 15px;
  object-fit: cover !important;
}

.yt_vid {
  height: 220px;
  width: 300px;
  border-radius: 15px;
  object-fit: cover !important;
}

.sx {
  height: 250px;
  width: 315px;
}

.forvid {
  height: 187px;
  width: 255px;
  border-radius: 15px;
  object-fit: cover !important;
}

.forsimilar1 {
  border-radius: 18px;
}

@media (max-width: 768px) {
  .for_upperdiv {
    padding: 0px;
  }
}

.high-lights-text {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding-left: 6px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .high-lights-text {
    font-size: 4vw;

    padding-left: 4%;
  }
}

@media only screen and (max-width: 768px) {
  .high-lights-text {
    font-size: 1.5vw;
    padding-left: 4%;
  }

  .m_3 {
    margin-bottom: 30px !important;
  }

  .butnget {
    border-radius: 12px;
    background: #fff !important;
    width: 100px;
    height: auto;
    flex-shrink: 0;
    color: #000 !important;
    border: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .butnbook {
    border-radius: 12px;
    background: #ffa923;
    width: 100px;
    height: auto;
    flex-shrink: 0;
    color: #fff;
    border: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.img1 {
  width: 100%;
  height: 170px;
  flex-shrink: 0;
  border-radius: 15px;
}

.img2 {
  width: 100%;
  height: 170px;
  flex-shrink: 0;
  border-radius: 15px;
  margin-top: 12px;
}

.duratio-icon {
  width: 28.778px;
  height: 28.371px;
  flex-shrink: 0;
}

.br {
  width: 2px;
  height: 45px;
  background: #ffa923;
  margin-left: 10px;
}

.duration {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.duration-text {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.similarclasses {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  margin-left: -15px;
  text-align: left;
  color: #dc1b5e;
}

.night-text {
  color: #525252;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
}

.high-lights {
  width: 100%;
  height: 170px;
  flex-shrink: 0;
  background: #ffa923;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .maindate {
    font-size: 4px;
  }
}

.fallhead {
  font-family: "Marck Script";
  color: #fff;
  font-size: 42.279px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  flex-grow: 10;
  filter: drop-shadow(0px 2px 3px black);
}

.icon_bx {
  height: 35px;
  width: 35px;
}
.for_girlicon {
  cursor: pointer;
  width: 40px;
  height: 50px;
  border-radius: 2em;
  margin-bottom: 1rem;
}

.center-menu_chat {
  position: fixed;
  z-index: 999;
  bottom: 10px;
  right: 1.5em;
}

.fallhead2 {
  color: #fff;
  font-family: "Marck Script";
  font-size: 39.279px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -10.964px;
}

.fallhead3 {
  color: #fff;
  font-family: "Marck Script";
  font-size: 45.279px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.964px;
}

@media only screen and (max-width: 400px) {
  .fallhead2 {
    font-size: 35px;
    float: none;
  }

  .for_btntab {
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 9px;
  }

  .fallhead3 {
    font-size: 25px;
    float: none;
  }
}

.kashmirhead {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 5.841px;
  filter: drop-shadow(2px 4px 6px black);
  margin-top: -15px;
}

@media only screen and (max-width: 768px) {
  /* Adjust styles for screens up to 768px wide */
  .fallhead {
    font-size: 30px;
    letter-spacing: -1px;
  }

  .kashmirhead {
    font-size: 25px;
    letter-spacing: 3px;
  }
}

.contdiv {
  position: relative;
}

.Main_div {
  /* position: relative; */
  background-image: url(/src/assets/images/Tripdetailspageimage.jpg);
  padding-top: 4rem;
  height: 85vh;
  object-fit: cover;
  /* align-items: center;
    justify-content: center; */
  display: flex;
  background-repeat: no-repeat !important;
  /* width: 100vw; */
  margin: 0 auto;
  background-size: cover;
  background-position: center;
  /* padding-left: 6rem; */
}

.butn-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: space-between;
  align-self: flex-end;
}

.grid-container {
  display: grid;

  padding: 10px;
  height: 100%;
  width: 100%;
}

.trip_slider {
  height: 255px !important;
  width: 255px !important;
  object-fit: contain !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.trip_slider_video {
  height: 187px !important;
  width: 255px !important;
  object-fit: contain !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.grid-item {
  /* text-align: center; */
  padding: 2px;
  font-size: 30px;
}

.item1 {
  grid-column: 1 / span 2;
  grid-row: 1;
  height: 160px;
  overflow: hidden;
}

.item2 {
  grid-column: 4;
  grid-row: 1 / span 1;
  text-align: center;
  display: flex;
  justify-content: end;
  align-content: right;
}

.item5 {
  grid-column: 4 / span 1;
  grid-row: 3;
  margin-bottom: 19px !important;
  align-self: flex-end;
  align-items: flex-end;

  align-content: right;
  display: flex;
  justify-content: end;
}

.butnget {
  border-radius: 12px;
  background: #fff !important;
  width: 190px;
  height: auto;
  flex-shrink: 0;
  color: #000 !important;
  border: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.butnbook {
  border-radius: 12px;
  background: #ffa923;
  width: 190px;
  height: auto;
  flex-shrink: 0;
  color: #fff;
  border: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.gallery_bx {
  background-color: #ffa923;
  height: 60%;
  border-radius: 15px;
  color: white;
  font-size: 30px;
  font-weight: 700;
  padding: 10%;
}

.less-icon-golaphoto {
  margin-top: 12%;
  background: white;
  border-radius: 100%;
  border: 1px solid white;
  padding: 1px 7px 4px 5px;
  cursor: pointer;
}

.gallery_bx {
  background-color: #ffa923;
  height: 60%;
  border-radius: 15px;
  color: white;
  font-size: 30px;
  font-weight: 700;
  padding: 10%;
}

.butnbook:hover {
  background: #ffa923;
}

.less-icon-golaphoto {
  margin-top: 12%;
  background: white;
  border-radius: 100%;
  border: 1px solid white;
  padding: 1px 7px 4px 5px;
  cursor: pointer;
}

.less-icon-gola:hover {
  background: #dc1b5e;
  color: white;
  border: none;
  cursor: pointer;
}

.simTrip {
  font-size: 24px;
  font-weight: 700;
}

.high-lights-text {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding-left: 6px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .high-lights-text {
    font-size: 4vw;
    padding-left: 4%;
  }
}

@media only screen and (max-width: 768px) {
  .high-lights-text {
    font-size: 1.5vw;
    padding-left: 4%;
  }

  .item1 {
    grid-column: 1 / span 2;
    grid-row: 1;
  }
}

.high-lights {
  width: 100%;
  height: 170px;
  flex-shrink: 0;
  background: #ffa923;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  background: #ffa923;
  border: none !important;
  border-radius: 40px;
  padding: 9px 0px !important;
  color: white;
}

.img1 {
  width: 100%;
  height: 170px;
  flex-shrink: 0;
  border-radius: 15px;
}

.img2 {
  width: 100%;
  height: 170px;
  flex-shrink: 0;
  border-radius: 15px;
  margin-top: 12px;
}

.followUp_icon {
  width: 30px;
  height: 35px;
}

.duration-text {
  color: #000;
  font-size: 14px;
  margin-left: -2px;
  font-family: "inter";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.night-text {
  color: #525252;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
}

.for_shri_div {
  text-align: justify;
  border-radius: 10px;
  background: #fff !important;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0% !important;
  align-items: center;
}

.day1 {
  color: #dc1b5e;
  font-size: 15px;
  font-weight: 600;
}

.shri_buttn {
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: justified;
  padding: 1% !important;
  border-left: 0.5px solid rgba(255, 169, 35, 1) !important;
  background-color: #ffffff !important;
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
  color: black !important;
}

.accordion-button:focus {
  border: white;
}

.accordion-button:not(.collapsed) {
  background-color: #dfdfdf !important;
  color: black !important;
}

.accordion {
  --bs-accordion-btn-focus-border-color: none;
  --bs-accordion-btn-focus-box-shadow: none;
}

.for_shri2_div {
  text-align: justify;
  border-radius: 10px;
  background: #f6f6f6;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 4px;
}

.for_contentundershri {
  margin-left: -9px;
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  line-height: 22px;
  letter-spacing: 0em;
  color: #000000;

  color: #000000;
}

.for_contentunderpink {
  color: rgba(220, 27, 94, 1);
}

@media (max-width: 480px) {
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    background: #ffa923;
    border: none !important;
    border-radius: 40px;
    padding: 10px 10px !important;
    color: white;
  }

  .for-view-accordion * {
    padding-bottom: 0% !important;
  }

  .shri_buttn {
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: justified;
    padding: 1% !important;
    border-left: 0.5px solid rgba(255, 169, 35, 1) !important;
    background-color: #ffffff !important;
  }

  .for_highlight_content {
    font-size: 13px;
  }

  .for_font {
    font-size: 12px !important;
  }

  .highLights {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 18px;
  }

  .Itinerary_images {
    width: 100%;
    height: 5rem;
    object-fit: cover;
    aspect-ratio: 5 / 3;
    border-radius: 9%;
    max-width: 100%;
  }

  .mainimg {
    height: 250px !important;
    border-radius: 15px;
    object-fit: cover !important;
    position: relative;
  }

  .forsimilar {
    height: 250px;
    width: 255px;
    border-radius: 15px;
    object-fit: cover !important;
  }

  .childmainimg {
    position: absolute;
    bottom: 0px;
    height: 30% !important;
  }

  .for-mobile-view-section {
    margin-top: 6% !important;
  }

  .similar-trips-view {
    padding: 0% 4% 3% 4% !important;
  }

  .similarclasses {
    font-size: 17px !important;
  }

  .mobile-properties {
    align-items: baseline !important;
    display: inline;
  }

  .for-mobile-properties2 {
    color: rgb(220, 27, 94);
    font-weight: 500;
    margin-left: 2% !important;
    text-align: -webkit-auto;
    font-size: 13px;
  }

  .simTrip {
    font-size: 17px !important;
  }

  .Highlights-mobile-view {
    margin-bottom: 6% !important;
  }

  .childmainimg {
    font-size: 17px !important;
  }

  .data-night-view {
    font-size: 14px !important;
  }

  .for_date_icon {
    font-size: 16px !important;
  }

  .for-view-accordion {
    font-size: 14px !important;
    margin-left: 3%;
    text-align: justify;
    text-justify: inter-word !important;
    word-spacing: 0.1em !important;
  }

  .for-inclusion-text {
    font-size: 14px !important;
    margin-left: 4%;
    text-align: justify;
    text-justify: inter-word !important;
    word-spacing: 0.1em !important;
  }

  .for_exclusioncontent {
    font-size: 14px !important;
    text-align: justify;
    text-justify: inter-word !important;
    word-spacing: 0.1em !important;
    font-weight: 400;
  }

  .Exclusions_div {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 14px 20px 12px 11px;
  }

  .parentdiv3 {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 19px 20px 12px 12px;
  }

  .highlight_div {
    margin-top: 6% !important;
  }

  .for_note_div {
    margin-top: 6% !important;
  }

  .for_btntab {
    color: black;
  }

  .for_firsthead {
    color: #000;
    text-align: justify;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    font-size-adjust: inherit;
    line-height: 150%;
  }

  .photocard {
    position: relative;
    height: 250px;
    border-radius: 15px;
    object-fit: cover !important;

    width: 71% !important;

    background-position: center !important;
    background-size: cover;
  }

  .item1 {
    grid-column: 1 / span 2;
    grid-row: 1;
  }

  .nav-tabs {
    border: 2px solid #ffa923;
    border-radius: 50px;
    margin: 0px !important;
    padding: 0;
  }

  .Main_div {
    position: relative;
    padding-top: 0rem;

    background-repeat: round;
    width: auto;
    margin: 0;
    background-size: cover;
    padding-left: 0rem;
  }

  .butnget {
    border-radius: 12px;
    background: #fff !important;
    width: auto;
    height: auto;
    flex-shrink: 0;
    color: #000 !important;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .butnbook {
    border-radius: 12px;
    background: #ffa923;
    width: auto;
    height: auto;
    flex-shrink: 0;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .parentdiv2 {
    position: sticky;
    top: 0;
    margin-top: 6%;
  }

  .forsmalldevicebttn {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .butnget {
    margin-right: 11px;
  }

  .grid_small {
    height: 0;
  }

  .fallhead {
    font-size: 25px;
    letter-spacing: -1px;
  }

  .kashmirhead {
    font-size: 17px !important;
    letter-spacing: 0px;
  }

  .forsvg {
    border: 3px solid white;
    border-radius: 100px;
    padding: 6%;
    font-size: 156%;
  }

  .forsvg2 {
    font-size: 50px;
  }

  .smallgridgap {
    margin-top: 12%;
  }

  .high-lights-text {
    font-size: 1.5rem;
  }

  .smalldevicefor {
    margin-top: 28px !important;
  }

  .imgpx {
    width: 100%;
    height: 96px !important;
    flex-shrink: 0;
    border-radius: 15px;
  }

  .for_smallflex {
    display: flex;
    align-items: center !important;
  }

  .smalltextt {
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: justified;
  }

  .Get-quote-btn {
    font-size: 16px;
    padding: 3px 8px;
    margin-top: 15% !important;
    border: none;
  }

  .for-enquriy {
    margin-top: 6% !important;
  }

  .notesection {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
  }

  .btn-tour {
    color: #fff;
    background-color: #ffa923;
    border-color: #ffa923;
    font-size: 17px;
    font-weight: 600;
    border-radius: 12px;
  }
}

.btn-tour {
  color: #fff;
  background-color: #ffa923;
  border-color: #ffa923;
  font-size: 18px;
  font-weight: 600;
  border-radius: 12px;
}

.btn-tour:hover,
.btn-tour.active,
.btn-tour:active {
  background: #ffa923;
  color: #ffffff;
  border-color: #ffa923;
}

@media (max-width: 375px) {
  .photocard {
    position: relative;
    height: 250px;
    border-radius: 15px;
    object-fit: cover !important;
    width: 79% !important;
    background-position: center !important;
    background-size: cover;
  }
}

@media (max-width: 320px) {
  .csty {
    color: #dc1b5e;
    font-weight: 700;
    font-size: 15px;
  }

  .btn-tour {
    color: #fff;
    background-color: #ffa923;
    border-color: #ffa923;
    font-size: 15px;
    font-weight: 600;
    border-radius: 12px;
  }

  .for_font {
    font-size: 9px !important;
  }

  .lsty {
    font-size: 15px;
  }

  .butnbook {
    border-radius: 12px;
    background: #ffa923;
    width: auto;
    height: auto;
    flex-shrink: 0;
    color: #fff;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .butnget {
    border-radius: 12px;
    background: #fff !important;
    width: 100px;
    height: auto;
    flex-shrink: 0;
    color: #000 !important;
    border: none;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    background: #ffa923;
    border: none !important;
    border-radius: 40px;
    padding: 10px 10px !important;
    color: white;
  }

  .photocard {
    position: relative;
    height: 250px;
    border-radius: 15px;
    object-fit: cover !important;
    width: 94% !important;
    background-position: center !important;
    background-size: cover;
  }

  .nav-tabs {
    border: 2px solid #ffa923;
    border-radius: 50px;
    margin: 0px !important;
    padding: 0;
  }

  .Main_div {
    position: relative;

    padding-top: 0rem;
    display: flex;
    background-repeat: no-repeat;
    width: auto;
    margin: 0;
    background-size: contain;
    padding-left: 0rem;
  }

  .parentdiv2 {
    margin-top: 6%;
  }

  .forsmalldevicebttn {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-left: -73px;
  }

  .butnget {
    margin-right: 11px;
  }

  .grid_small {
    height: 0;
  }

  .fallhead {
    font-size: 25px;
    letter-spacing: -1px;
  }

  .kashmirhead {
    font-size: 20px;
    letter-spacing: 0px;
  }

  .forsvg {
    border: 3px solid white;
    border-radius: 100px;
    padding: 5%;
    font-size: 100%;
  }

  .forsvg2 {
    font-size: 50px;
  }

  .smallgridgap {
    margin-top: 12%;
  }

  .smallrowsize {
    margin-top: 11% !important;
  }

  .high-lights-text {
    font-size: 1.5rem;
  }

  .smalldevicesize {
    margin-top: -11px !important;
  }

  .smalldevicefor {
    margin-top: 28px !important;
  }

  .imgpx {
    width: 100%;
    height: 96px !important;
    flex-shrink: 0;
    border-radius: 15px;
  }

  .for_smallflex {
    display: flex;
    align-items: center !important;
  }
}

@media only screen and (max-width: 992px) {
  .tourtt {
    width: 100%;
  }

  .Main_div {
    position: relative;
    padding-top: 0rem;
    height: 250px;
    background-repeat: round;
    width: auto;
    margin: 0;
    background-size: cover;
    padding-left: 0rem;
  }

  .butnget {
    border-radius: 12px;
    background: #fff !important;
    width: 120px;
    height: auto;
    flex-shrink: 0;
    color: #000 !important;
    border: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .butnbook {
    border-radius: 12px;
    background: #ffa923;
    width: 100px;
    height: auto;
    flex-shrink: 0;
    color: #fff;
    border: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.properties-list {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .properties-list {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
  }
}
.for_option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}
.popup-container {
  position: fixed;
  bottom: 8%;
  right: 18%;
  z-index: 1000;
  border-radius: 2%;
}
.for_callexpert {
  color: #000 !important;
  text-decoration: none;
  padding: 5px 0px;
}
.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 200px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}
