
.left_txt {
  font-weight: 600;
  margin: 2px;
  width: 35%;
  font-size: 75%;
}
 .semicolon{
  color: #5c5c5c;
  width: 5%;
  font-weight: 600;
 }
 .right_txt{
    color: #5c5c5c;
    width: 60%;
    font-size: 75%; 
    word-break: break-word;
  }

  .desri_sty {
    font-size: 14px;
    margin-left: 34px;
    position: relative;
    bottom: 12px;
}
.expdat{
  font-weight: 600;
   font-size: 14px;
    margin-left: 34px;
    position: relative;
    bottom: 12px;
}

 .detail_bx{
  width: 31%;
  margin-right: 1%;
  
 }
 .txt_head{
  font-weight: 700;
  font-size: 105%;
 }
 .pkg_left_txt {
  color: #5c5c5c;
  margin: 2px;
  width: 30%;
  font-size: 87%;
}
 
 .pkg_right_txt {
  color: #5c5c5c;
  width: 60%;
  font-size: 87%;
  justify-content: end !important;
}
  .bottom_detail_bx{
    margin: 2px;
  }
._amount{
  color: #5c5c5c;
  font-weight: 600;
  font-size: 120%;
}
._amountf{
  color: #5c5c5c;
  font-weight: 700;
  font-size: 130%;
}
.text_pink{
  color: #dc1b5e;
}
.payment_btn{
  background: linear-gradient(90.03deg, #FFA923 33.85%, #DC1B5E 98.08%);
 border-style: none;
 height: 40px;
 width: 200px;
 border-radius: 15px;
 font-size: 120%;
 color: white;
 padding: 2px;
}
.main_bx{
  box-shadow: 5px 10px 18px #888888;
  font-size: 110%;
}
.notes_txt{
  color: #5c5c5c;
}
.bgpink{
  background-color: #dc1b5e;
  height: 40px;
  color:aliceblue;
  width: 200px;
}
.couponbtn:hover{
  background-color: #dc1b5e;
  color: #fff;
  padding: 8px;
}
.text_pink{
  color: #DC1B5E;
  font-size: 110%;
  border-radius: 25px;
}
.code_applybtn{
  background-color: #FFA923;
  color:aliceblue;
  border-radius: 25px;
  font-size: 110%;  
}
.place2{
color: #c2c2c2;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 4.847px;
background: white;
}
.code_applybtn:hover{
  background-color:#FFA923;
  font-size: 110%;

  border-radius: 25px;
}
.brder_popup{
  border-radius: 35px;
  padding: 20px;
}
.coupon_diff{
  font-size: 80%;
}
.left_pkg{
  width: 50%;
}
.right_pkg{
  width: 50%;
}
@media only screen and (max-width: 850px) {
.termandc{
  text-wrap: nowrap;
}
.text_pink {
  color: #dc1b5e;
  font-size: 100%;
  border-radius: 25px;
   text-wrap:balance;
  margin-right: 1rem !important;
}
}

@media only screen and (max-width: 1000px) {
  
  .respn{
    width: 100%;
    flex-wrap: wrap;
  }
  /* .respn2{
    width: 100%;
  } */
  /* .left_pkg{
    width: 100%;
  }
  .right_pkg{
    width: 100%;
  } */
  .pkg_left_txt{
    color: #5c5c5c;
    margin: 2px;
    width: 40%;
   }

   
  
}
@media only screen and (max-width: 769px) {
  .fnt{
    font-size: 80%;
  }
  .pkg_left_txt{width: 25%;}
  .main_bx{
    margin: 2% !important;
  }
}
@media only screen and (max-width: 490px) {
  .fnt{
    font-size: 70%;
  }
  /* .pkg_left_txt{width: 25%;}
  .main_bx{
    margin: 2% !important;
  } */
}
@media only screen and (max-width: 360px) {
  .fnt{
    font-size: 60%;
  }
  /* .pkg_left_txt{width: 25%;}
  .main_bx{
    margin: 2% !important;
  } */
}