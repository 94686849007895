.placegeneral {
  color: black !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  border: none;
  border-radius: 0;
  text-align: center;
  line-height: 22px;
  padding: 0%;
}

.label-general {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(107, 107, 107, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(102, 102, 102, 1);
}

.formgeneral {
  background: rgba(255, 234, 201, 1);
  padding: 1rem;
  padding-bottom: 0%;
  border-radius: 15px 15px 0px 0px;
  margin-top: -0.5rem;
}

.placegeneral::placeholder {
  color: black;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  background: rgba(255, 234, 201, 1);
}

.label-general2 {
  text-align: center;
  display: flex;
  padding: 3px;
  color: black;

  line-height: 22px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(102, 102, 102, 1);

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.formgeneral2 {
  border-radius: 5px;
  padding: 1rem;
}
.Partial-text {
  color: rgba(241, 104, 38, 1);
}

.placegeneral3 {
  color: black !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    border: none;
    border-radius: 0;
    text-align: center;
    line-height: 22px;
    padding: 0%;
    background: none !important;
}

.placegeneral20 {
  color: black !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  border: none;
  border-radius: 0;
  text-align: center;
  line-height: 22px;
  padding: 0%;
}

.label-general3 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(107, 107, 107, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  border: none;
  border-radius: 0;
}
.placegeneral3::placeholder {
  color: black;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: center;

}
.placegeneral20::placeholder {
  color: black;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
 
}

.label-general4 {
  text-align: center;
  display: flex;
  padding: 3px;
  color: rgba(107, 107, 107, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
}

.Partial-textred {
  color: rgba(255, 0, 0, 1);
}

.invoice-btn {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  background-color: white;
  color: rgba(255, 169, 35, 1);

  border-radius: 11.99px;
}

.label-generalpart {
  text-align: center;
  display: flex;
  padding: 3px;
  color: rgba(107, 107, 107, 1);
  justify-content: center;
  align-items: center;
  line-height: 22px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(102, 102, 102, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.generalbtn-container {
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  align-items: center;
}

.cancl-btn {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  border: 1.63px solid rgba(255, 169, 35, 1);
  background-color: white;
  border-radius: 11.99px;
}

.paynow-btn {
  border: 1.63px solid rgba(255, 169, 35, 1);
  background-color: rgba(255, 169, 35, 1);
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 11.99px;
}

@media (max-width: 480px) {
  .Partial-textred {
    color: rgba(255, 0, 0, 1);
    line-height: 25px;
  }
  .for-invoice-mobile{
    text-align: center;
    text-decoration: none;
    margin: 0px !important;
    padding: 0px !important
  }
  .generalbtn-container {
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
  }
 
}

@media (max-width: 768px) {
  .bigcard-general {
    margin-top: 25px !important;
  }
}
