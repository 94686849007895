.for_icons {
  background-color: white;
  color: black;
  font-size: 20px;
  border-radius: 50%;
  margin-top: 5px;
}
 .imagelogoo{
    width: 150px;
    height: auto;
  }
.button2{
  display: flex;
  cursor: pointer;
  background-color: #000000 !important;
  border: none;
  border-radius: 8px;
}
.button{
  display: flex;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #000000 !important;
}
.for_icons:hover {
  background-color: #dc1b5e;
  color: white;
}

.for_about2 {
  width: 88%;
}
.font_heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
}
.for_link {
  font-weight: 500;
  color: black;
  text-decoration: none;
}

.for_link:hover {
  color: #dc1b5e;
}

.text {
  color: white;
}

.about_list {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: justify !important;
}

@media (max-width: 480px) {
  .about_list {
    font-size: 11px !important;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: justified !important;
    color: #000000;
    text-align: justify !important;
  }
  .custom-mobile-view {
    margin-top: 0% !important;
  }
  .for_about2 {
    width: 100%;
  }
  .for_link {
    font-size: 10px !important;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
  }
  .forsmallfooterlist {
    margin-top: 3% !important;
  }
  .font_heading {
    font-size: 14px !important;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    margin-bottom: 3% !important;
  }
  .font_heading2 {
    font-size: 14px !important;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    margin-bottom: 3% !important;
  }
  .for_smallheading {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
  }
  .for_luckybakery {
    font-size: 10px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0em;
    margin-top: -2%;
  }
  .forsmallhide {
    display: none;
  }
  .for_smalldetails {
    font-size: 9px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0em;
  }
  .forsmallforspace {
    margin-top: 3%;
  }
  .forrights {
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0em;
  }
  .for_smalldf {
    flex-direction: column;
    text-align: center;
  }
  .quicklink {
    padding: 15px !important;
  }
}

.font_heading2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  margin-bottom: 3rem !important;
}

.font-address {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
}

@media (max-width: 600px) {
  .imagelogoo{
    width: 120px;
    height: auto;
  }
  .about_list {
    font-size: 11px !important;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: justify !important;
    color: #000000;
  }
  .for_about2 {
    width: 100%;
  }
  .for_link {
    font-size: 10px !important;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
  }
  .forsmallfooterlist {
    margin-top: 3% !important;
  }
  .font_heading {
    font-size: 14px !important;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    margin-bottom: 3% !important;
  }
  .font_heading2 {
    font-size: 33px !important;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    margin-bottom: 3% !important;
  }
  .for_smallheading {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
  }
  .for_luckybakery {
    font-size: 10px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0em;
    margin-top: -2%;
  }
  .forsmallhide {
    display: none;
  }
  .for_smalldetails {
    font-size: 9px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0em;
  }
  .forsmallforspace {
    margin-top: 3%;
  }
  .forrights {
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0em;
  }
  .for_smalldf {
    flex-direction: column;
    text-align: center;
  }
  .quicklink {
    padding: 15px !important;
  }

  .font-address {
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
  }
}
.iconcolor {
  color: black !important;
}
