.vertical-line {
  border-right: 1px solid #ddd;
}
.loremone{
  font-size: 12px;
}
.profile-image {
  border-radius: 50%;

  margin-right: 10px;

  margin-bottom: 25px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border: 2px solid #dc1b5e;
  background: #fff;
  border-radius: 22px;
  color: #dc1b5e;
  padding: 5%;
  width: auto;
}
.mt-4,
.my-4 {
  margin-top: 2.5rem;
}

.mt-5,
.my-5 {
  margin-top: -2rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.card-title {
  margin-bottom: 0rem;
}

.text-dark {
  color: black;
}

.colBlack {
  color: black;
  text-align: center;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.editbtn {
  color: #dc1b5e !important;
  float: right;
}

.mainc {
  display: flex;
  flex-direction: row;
  color: black;
  padding-left: 13%;
  position: relative;
  top: -2rem;
  max-width: 825px;
  right: 15rem;
}
.bigdivc {
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  width: auto;
  max-width: 825px;

  height: auto;
  flex-shrink: 0;
  /* padding: 40px; */

  display: flex;
  flex-wrap: nowrap;
  margin-right: 5%;
}
.myvoucher {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.smalldiv {
  border-radius: 0px 10px 10px 0px;
  background: #f5f5f5;
  /* max-width: 390px; */
  /* max-width: 47%; */
  /* padding-right: 2px; */
  flex-shrink: 0;
  height: 180px;
  /* margin-right: 1rem;
  margin-top: 1rem; */
  /* gap: px !important; */
  margin: 10px;
  padding: 0px;
  /* margin: 10px; */
}
.bigdivc2 {
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  width: auto;
  max-width: 825px;
  height: auto;
  flex-shrink: 0;
  padding: 40px;

  display: flex;
  flex-wrap: nowrap;
  margin-right: 5%;
}
.smallmain {
  display: flex;
  flex-direction: row;
  margin-left: 2px;
}

.subtract {

  height: 100%;
  width: 100%;
}

.word {
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-color: #df225a;
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  text-wrap: nowrap;
}
.pink {
  position: relative;
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: center;
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .text-right {
    text-align: left !important;
  }

  .d-mob {
    display: block !important;
  }
  .bigdivc {
    max-width: 100%;
    height: auto;
  }
  .bigdivc2 {
    max-width: auto;
    height: auto;
  }
  .smalldiv {
    max-width: 100%;
    border-radius: 10px;
    display: flex;
    margin-right: 0px !important;
  }
  .smallmain {
    flex-direction: column;
  }
  .nav-pills .nav-link.active{
    margin: 5px 52px 52px 52px;
  }

}

@media only screen and (min-width: 768px) {
  .fRight {
    float: right;
  }
  .mainc {
    padding-left: 3%;
    flex-direction: column;
    width: auto;
  }
}

@media (max-width: 320px) {
  .mainc {
    flex-direction: column;
    margin-top: 22rem;
  }
}
@media (max-width: 700px) {
  .mainc {
    flex-direction: column;
    margin-top: 15rem;
  }
}

@media (max-width: 420px) {
  .bigdivc {
    max-width: 100%;
    height: auto;
    margin-top: 8rem;
  }
}

@media (max-width: 420px) {
  .bigdivc2 {
    max-width: auto;
    height: auto;
    margin-top: 4%;
  }
}
@media (max-width: 420px) {
  .bigdivc2 {
    max-width: 100%;
    height: auto;
    margin-top: 4%;
  }
}


@media only screen and (max-width: 556px) {
  .word {
    margin: 10px 0;
    -ms-transform: rotate(0deg);
    transform: rotate(-90deg);
    position: static;
    width: auto;
    text-align: left;
    position: relative;
    top: -6rem;
  }
}

@media only screen and (max-width: 600px) {
  .pink {
    display: inline-block;
  }
}

.sidepink {
  display: inline-block;
  position: relative;
  bottom: 10rem;
  width: auto;
  padding-left: 3rem;
}
@media only screen and (max-width: 600px) {
  .sidepink {
    display: block;
    position: static;
    bottom: 0;
    width: auto;
    padding-left: 1rem;
    margin-top: 17px;
  }
  .lorem {
    font-size: 15px !important;
  }
  .yelobtn {
    font-size: 15px !important;
  }
  .pinkline {
    color: #df225a;
    font-size: 15px !important;
  }
}

.lorem {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2rem;
  padding-right: 5%;
}

.yelobtn {
  border-radius: 10px;
  background: #ffa923;
  max-width: 25%;
  flex-shrink: 0;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .yelobtn {
    max-width: 100%;
    margin-left: 0;
  }
}

.pinkline {
  color: #df225a;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
  border-bottom: 2px dotted #df225a;
  padding-bottom: 4px;
}

.list {
  margin-top: 2%;
}

#coupnbtn {
  border-radius: 24.519px;
  border: 1.962px solid #dc1b5e;
  background-color: white;

  line-height: normal;

  text-align: center;

  color: #dc1b5e;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15%;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 768px) {
  #coupnbtn {
    font-size: 16px;

    padding: 4%;
  }
}

.hellokrishna {
  padding-bottom: 5%;
}

@media screen and (max-width: 768px) {
  .hellokrishna {
    margin-bottom: 18%;
  }
}



.listicon {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}




@media only screen and (max-width: 350px) {
  .sidepink {
    display: block;
    position: static;
    bottom: 0;
    width: auto;
    padding-left: 1rem;
    margin-top: 17px;
  }
  .lorem {
    font-size: 13px !important;
    padding-right: 0%;
  }
  .yelobtn {
    font-size: 13px !important;
  }
  .pinkline {
    color: #df225a;
    font-size: 13px !important;
  }
}
