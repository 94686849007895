.for_personalized_under {
  position: absolute;
  top: 15px;
  left: 20px;
}
.for_personalized {

  object-fit: cover;
  position: relative;
  clip-path: polygon(0 0, 93% 0, 48%, 100%, 0% 100%, 0% 75%);
}

.personalized_img {
  position: relative;
}

.customnav {
  margin: 0px 309px !important;
}

.nav-item:hover {
  border: none !important;
}

.for_personalizedimg {
  width: 100%;
  height: auto;
}

.personalized_img_child {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.for_personalizedtext {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-align: center;
  color: white;
  text-shadow: 0 0 black;
  filter: drop-shadow(0px 2px 4px black);
}
.nav-tabs .nav-link {
  border: none;
}

.persnalimg {
  border-top-right-radius: 1;
  height: 174px !important;
  width: auto !important;
}
.personalhead {
  color: rgba(220, 27, 94, 1);
}
.for_main_head {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
}
.for_maindiv {
  margin-top: 60%;
}
.for_reviews {
  padding-top: 4px;
  color: white;
}
.for_rating {
  color: white;
  padding-top: 5px;
}
.for_underdrop {
  padding: 5px 25px 6px 12px;
  color: white;
  font-size: 17px;
  font-weight: 600;
  box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  text-wrap: nowrap;
}

.card-img-top-personalized {
  height: 100% !important;
  width: 100% !important;
  border-radius: 0px !important;
  object-fit: cover !important;
  cursor: pointer;
  aspect-ratio: 3 / 2 !important
}

@media (max-width: 768px) {
  .personalized_img_child {
    text-align: center;
    left: 47%;
    position: absolute;
    top: 40%;
  }
  .for_personalizedtext {
    font-size: 4vw;
  }

  .for_secondtext2 {
    font-size: 19px !important;
    font-weight: 600;
    line-height: 11px;
    letter-spacing: 0em;
    padding: 5px 29px 5px 24px !important;
  }
  .forall {
    margin-top: 12px !important ;
  }
  .for_underdrop {
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    padding: 8px 25px 6px 12px;
  }
}
@media (max-width: 480px) {
  .personalized_img_child {
    top: 33%;
    text-align: center;
    left: 49%;
  }
  .for_personalizedtext {
    font-size: 7vw;
    color: white;
  }
  .for_secondtext2 {
    font-size: 9px !important;
    font-weight: 600;
    line-height: 11px;
    letter-spacing: 0em;
    padding: 7px 6px 7px 13px !important;
  }
  .forall {
    margin-top: 0px !important;
  }
  .for_personalized_under {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 84% !important;
  }
  .for_underdrop {
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    padding: 8px 19px 16px 5px;
    padding-bottom: 3% !important;
 }
 .mobile-personalized{
  margin-top: 0% !important;
 }

  .for_main_head {
    display: none;
  }
  .for_smallmargin {
    margin-top: 1% !important;
  }
  .customnav {
    margin: 0px !important;
  }
}

.triangle {
  position: relative;
  width: 200px; 
  height: 100px;
  background-color: #800000; 

 
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0% 75%);
}

.triangle::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 20px 20px 0;
  border-style: solid;
  border-color: transparent #ffffff transparent transparent; 
}
