.profile-image-container {
  width: 10rem;
  height: 10rem;
  overflow: hidden;

  position: relative;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-icon-container:hover .profile-image {
  filter: blur(1px);
  cursor: pointer;
}

.update-profile-options {
  position: absolute;
  top: 50%;

  transform: translate(-50%, -50%);

  padding: 1.5%;

  border-radius: 1vw;
}

#bg-grediant {
  color: white;
  padding: 60px;
  border-radius: 0px;
  position: relative;
  background: -webkit-linear-gradient(180deg, #ffa923 1.68%, #dc1b5e 97.49%);
}

.customer-div {
  display: flex;
  flex-direction: row;
}

.smallcard {
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  height: 100%;
}

.bigcard {
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem;
}

.user-Name-text {
  font-size: 2rem;
  letter-spacing: 0em;
  text-align: left;
  text-wrap: nowrap;
  padding-left: 5%;
}

.user-mail-text {
  font-size: 1rem;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5%;
}

.vertical-line1 {
  border-left: 3px solid #fff;
  height: 22vh;
  width: 2px;
  margin-top: 3%;
  margin-left: 20%;
}

.hello-user_name-p2 {
  width: 100%;
  text-align: start;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  margin-bottom: 40px;
}

.maincard {
  position: relative;
  bottom: 2rem;
}

.forupeer {
  position: relative;
  bottom: 3rem !important;
}

.maincard {
  flex-direction: row;
  position: relative;
}

.img-line {
  height: 120px;
  margin-top: 35px;
  margin-left: 55px;
}

.dash {
  color: #000;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  margin-bottom: 18%;
  text-align: center;
}

.user-div-2 {
  margin-left: 12%;
}

@media (min-width: 600px) and (max-width: 1000px) {
  .kd {
    width: fit-content;
  }

  .nonel {
    border: none;
  }

  .gmail {
    width: max-content;
  }

  .for_smallnavpill {
    margin: 100px;
  }

  .bigcard {
    margin-top: 6%;
    margin-bottom: 6%;
  }

  .user-div-2 {
    margin-left: 0%;
  }
}

.for_smallnavpill .nav-link:hover {
  color: #dc1b5e !important;
  /* background-color: #ffa923 !important; */
}

@media only screen and (max-width: 600px) {
  .imagePic {
    width: 85%;
  }

  .maincard {
    padding-left: 0rem;
  }

  #bg-grediant {
    padding: 0px;
  }

  .dash {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5% !important;
    text-align: center;
  }

  .img-line {
    height: 120px;
    margin-top: 35px;
    margin-left: 55px;
    display: block;
  }

  .user-div-2 {
    padding-top: 10px;
    margin-left: 0% !important;
  }

  .user-Name-text {
    font-size: 1.4rem;
    padding-left: 0% !important;
  }

  .user-mail-text {
    font-size: 0.7rem;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 0% !important;
  }

  .vertical-line1 {
    margin-left: 0% !important;
    display: none;
  }

  .bigcard {
    margin-top: 6%;
    margin-bottom: 6%;
  }
  hello-user_name-h4 .hello-user_name-h4 {
    text-align: start;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    padding-top: 15px;
  }

  .hello-user_name-p {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    color: #fff;
  }

  .hello-user_name-p2 {
    width: 91%;
    text-align: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #fff;
    margin-bottom: 5px;
  }

  .hello-user_name-h4::before {
    content: "";
    background-color: yellow;
    color: red;
    font-weight: bold;
  }

  .forupeer {
    position: relative;
    margin-top: 1rem;
    bottom: 0% !important;
  }

  .smallcard {
    box-shadow: -4px 4px 4px 0px #00000040;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    margin: 0% 23% 9% 23%;
    border: 2px solid #dc1b5e;
    background: #fff;
    border-radius: 22px;
    color: #dc1b5e;
    padding: 2px;
  }

  .for_smallnavpill {
    padding-top: 1% !important;
  }
}

@media (max-width: 400px) {
  .user-div-2 {
    padding-top: 10px;
  }
  .imagePic {
    width: 70%;
  }
  .user-mail-text {
    font-size: 0.7rem;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 0% !important;
  }

  .user-Name-text {
    font-size: 0.9rem;
    padding-left: 0% !important;
  }
}
@media (max-width: 480px) {
  .user-Name-text {
    font-size: 22px !important;
  }
  .user-mail-text {
    font-size: 0.9rem;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 0% !important;
  }
  .imagePic {
    width: 90%;
  }
}

@media (max-width: 320px) {
  .user-div-2 {
    /* margin-left: 12%; */
    padding-top: 10px;
  }
  .imagePic {
    width: 70%;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    margin: 0% 18% 9% 23%;
    border: 1px solid #dc1b5e;
    background: #fff;
    border-radius: 22px;
    color: #dc1b5e;
  }
}

.lableedit {
  text-align: start;
  font-weight: 600;
  border-bottom: 2px solid black;
}
