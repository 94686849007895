.WishList-padding {
  padding-left: 30px;
}


.WishList-h1 {
  display: flex;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.horizontal-line {
  width: 100%;
  height: 0.5px;
  background: #b4b4bb;
  margin-bottom: 20px;
}

.WishList-card {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0.5px rgba(0, 0, 0, 0.25);
}
.WishList-content {
  text-align: center;
  padding: 15px;
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #ffa923;
  border-color: #0a58ca;
  box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5);
}

.WishList-h5 {
  text-align: start;
  font-size: 18px;
  color: #000;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: nowrap;
}


.wishlist-btn-btn {
  border-radius: 10px !important;
  background: #ffa923;
  align-items: center;
  border: none;
}

.wishlist-btn-btn:hover {
  background: #ffa923;
}


@media screen and (max-width: 768px) {
  .WishList-h5 {
    text-align: start;
    font-size: 12px;
    color: #000;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-wrap: nowrap;
  }

  .WishList-content {
    text-align: center;
    padding: 10px;
  }
}
.watch-Icon {
  fill: #dc1b5e;
  margin-right: 3px;
  text-align: start;
}
@media (max-width: 600px) {
  .watch-Icon {
    fill: #dc1b5e;
    margin-right: 0px !important;
    text-align: start;
  }
  .WishList-h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .Removebutton {
    margin-top: 11% !important;
    margin-top: 4% !important;
    text-align: justify;
    font-size: 12px;
  }
}
.card-img-wish {
  height: 138px;
  object-fit: cover;
    cursor: pointer;
}
@media screen and (max-width: 780px) {
  .bigcard {
    padding: 16px;
  }
}
