.loader-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 11111;
  background-color: rgba(94 90 90 / 53%);
  top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.body {
  background-color: rgba(117, 117, 117, 0.199);
}
.custom-loader {
  position: absolute;
  justify-content: center !important;
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #f16722;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
