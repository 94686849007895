.headingg {
  display: flex;
  justify-content: space-evenly;
  margin: revert;
}
.para_dis {
  margin-bottom: 2rem;
}
.psitr {
  position: absolute;
  bottom: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  width: 100%;
}
.bannerig {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    filter: brightness(50%);
    object-position: center center;
}
