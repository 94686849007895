.your-input-class:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0 !important;
}

.verifybtn:disabled {
  background-color: #d3d3d3;
  color: #7a7a7a !important;
  cursor: not-allowed;
}

.background2 {
  background-image: url(/src/assets/images/successfulbg.jpg.png);
  height: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  align-items: center;
  justify-content: center;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 400px) {
  .background2 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}

.wel2 {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  padding-bottom: 0rem;
  padding-top: 0rem;
}

.logincontent {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2rem;
}

.mainform {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .mainform {
    flex-direction: column;
  }
}

.bigdivforms {
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 540px;
  height: auto;
  max-height: 650px;
  flex-shrink: 0;
  padding: 2rem;
  flex-wrap: nowrap;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.6);
  position: relative; 
}

@media (max-width: 768px) {
  .bigdivforms {
    max-width: 100%;
    height: auto;
    background-size: contain;
  }
}

.sendotp {
  color: #fff !important;
  text-align: center;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  border-radius: 6.766px !important;
  border: 1px solid #dc1b5e !important;
  background-color: transparent;
  text-decoration: none !important;
  padding: 3px !important;
}

@media (max-width: 380px) {
  .sendotp {
    top: -0.2rem;
    position: relative;
    float: none;
    margin-right: 0rem;
    height: auto;
    width: auto;
    font-size: 10px !important;
  }
}

.sendlable {
  float: right;
  position: relative;
  top: 2.5rem;
}

@media (max-width: 320px) {
  .sendlable {
    position: relative;
    width: auto;
  }
}

.your-input-class {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #e0e0e0 !important;
  border-radius: 0 !important;
  color: #e0e0e0 !important;
}

.your-input-class::placeholder {
  color: #e0e0e0 !important;
}

.your-input-class:focus,
.your-input-class:active {
  border-bottom: 2px solid #e9ebf0 !important;
  /* box-shadow: 0 0 5px rgba(234, 237, 243, 0.5);  */
  box-shadow: none !important;
  /* border: none !important; */
}

@media only screen and (max-width: 320px) {
  .your-input-class {
    font-size: 12px !important;
  }
}

.verifybtn {
  border-radius: 15px !important;
  background: #dc1b5e !important;
  width: 445px;
  height: 42px;
  flex-shrink: 0;

  color: #fff !important;

  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none !important;
}

@media (max-width: 600px) {
  .verifybtn {
    font-size: 16px;

    height: auto;
    width: auto;
  }

  .background2 {
    background-image: url(/src/assets/images/successfulbg.jpg.png);
    height: 100%;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 !important;
    display: block;
  }

  .bigdivforms {
    border-radius: 0px !important;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
      -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100vw;
    height: 90vh;
    max-width: none;
    max-height: none;
    flex-shrink: 0;
    padding: 2rem;
    flex-wrap: nowrap;
    border-radius: 0 !important;
    background: rgba(0, 0, 0, 0.6);
    position: relative;
  }

  .verifybtn {
    padding: 1% 39%;
    text-align: center;
  }

  .logincontent {
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.53px;
    margin-bottom: 0rem;
  }
}

.lastpara {
  color: #fff;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 2rem;
}

.lastlink {
  color: #ffa923;
  text-decoration: none;

  font-weight: 600;
  line-height: normal;
}

.sendlable2 {
  float: right;
  position: relative;
  top: 2.4rem;
}

.resend {
  margin-top: -2rem;
}

.glabol1 {
  width: 170px;
}

.signarrow {
  color: #fff;
}

.colored-toast.swal2-icon-success {
  background-color: rgb(22, 153, 44) !important;
}

.colored-toast.swal2-icon-error {
  background-color: #db4343 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
  font-size: 15px !important;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}

.swal2-loading {
  animation: spin 2s linear infinite;
}

.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
  font-size: 14px !important;
}

div:where(.swal2-container) div:where(.swal2-loader) {
  border-color: #ffc409 rgba(0, 0, 0, 0) #ffc409 rgba(0, 0, 0, 0) !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  width: 24em !important;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
}
