.contactBox {
  height: 550px;
  font-size: 2.2vh;
  background-image: url(../../../assets/images/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 50%;
  padding-top: 10vh;
}

.bx {
  height: 90%;
  max-width: 60%;
}

.centerbx {
  height: 95%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.left {
  height: 100%;
  width: 60%;
  padding: 25px 20px;
  background-color: white;
  border-bottom: 1px solid rgb(171, 170, 170);
  border-left: 1px solid rgb(171, 170, 170);
  border-top: 1px solid rgb(171, 170, 170);
}

.right {
  min-height: 100%;
  min-width: 30%;
  position: relative;
  background-color: white;
  border-bottom: 1px solid rgb(171, 170, 170);
  border-right: 1px solid rgb(171, 170, 170);
  border-top: 1px solid rgb(171, 170, 170);
}

.contactinfo {
  color: white;
  position: absolute;
  min-height: 80%;
  background-color: #ffa923;
  width: 120%;
  right: -25%;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

input:focus {
  outline: none !important;
}

.heading-txt {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.iconss {
  margin: 2px;
}

@media only screen and (max-width: 1000px) {
  .contactBox {
    padding-top: 6vh;
    min-height: 90vh;
    margin-bottom: 20px;
  }
  #sndmsg {
    margin: 0px !important;
  }

  .bx {
    height: 53%;
    max-width: 90%;
  }

  .centerbx {
    width: 100%;
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .iconsprop {
    height: 30px;
    position: relative;
    right: 0rem !important;
}

  .left {
    width: 100%;
    border-right: 1px solid rgb(171, 170, 170);
  }

  .right {
    margin-top: -3vh;
    height: 80%;
    width: 100%;
    /* padding: 2px; */

    background-color: unset;
    border: none;
  }

  .contactinfo {
    background-color: #ffa923;
    height: 80%;
    width: 100%;
    /* font-size: 2vw; */
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 16px;
    padding: 10px;
    /* font-size: 3.5vw; */
  }
  
}


.iconsprop {
  height: 30px;
  position: relative;
  right: 5rem;
}

.idesign {
  border-style: none;
  border-bottom: 1px solid black;
  border-radius: 0%;
}

.idesign2 {
  border-style: none;
  border-bottom: 1px solid rgb(38, 34, 34);
  border-radius: 0%;
}

.sendbtn {
  margin-top: 18px !important;
  background-color: #ffa923;
  color: white;
  padding: 5px;
  width: fit-content;
  text-align: center;
}
.sendbtn:hover {
  border: 1px solid #000;
  color: #000;
}
@media only screen and (max-width: 500px) {
  .left {
    height: 100%;
  }
 
}
@media only screen and (max-width: 700px) {
  .contactinfo {
    /* font-size: 2vw; */
    font-size: 95%;
  }
 
}


@media only screen and (max-width: 275px) {
  .left {
    height: 150%;
  }
}
@media only screen and (max-width: 350px) {
  .contactinfo {
    /* font-size: 2vw; */
    font-size: 90%;
  }
 
}

.idesign input:focus {
  outline: none !important;
}

@media (max-width: 480px) {
  .idesign {
    font-size: 10px !important;
  }
}
