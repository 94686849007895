.bg_general{
  background-repeat: repeat-x;
  background: rgb(255, 239, 139);
}

.forupeergeneral {
  margin-top: 5rem;
  bottom: 0% !important;
}
.for-visible-tab{
   color: black !important; 
}
.dashgeneral {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8%;
  text-align: center;
  border: none !important;
  background: #fff;
  text-wrap: nowrap;
  

}

.smallcard-general {
  border-radius: 15px;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0rem;
  height: 100%;
  padding-top: 2rem;
  
}

.bigcard-general {
  border-radius: 15px;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
    -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  padding-top: 0.8;
  

  /* background-repeat: repeat-x; */
}


.nav-pills .nav-linkgeneral.active,
.nav-pills .show > .nav-linkgeneral {
  border: none;
  background-color: rgba(255, 234, 201, 1) ;
 width: 100%;
 color: black !important;
  padding: 1rem;
  border-left:  5px solid rgba(220, 27, 94, 1) !important;

 text-wrap: nowrap;
font-size: 20px;
font-weight: 500;
line-height: 11px;
letter-spacing: 0em;


}


.lableedit {
  text-align: start;
  font-weight: 600;
  border-bottom: 2px solid black;
}

.lable-trav{
 
font-size: 20px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;


}

