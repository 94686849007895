@media (max-width: 768px) {
  .navbar-toggler {
    padding: 0rem 0rem;
    font-size: 1rem;
  }

  .for-mobileview-header-submenu{
    padding-bottom: 3% !important;
    cursor: pointer;
}
.for-mobileview-header-submenu:hover{

    color: #dc1b5e;
}

  .navbar-nav {
    font-size: 16px;
    padding: 2px;
    display: flex;

    text-wrap: nowrap;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
  }

  .global-logo {
    width: 140px;
    flex-shrink: 0;
  }

  .nav-links {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }

  .nav-links a {
    text-decoration: none;
    color: #dc1b5e;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }

  .user-buttons {
    display: flex;
    align-items: center;
  }

  .enquiry-btn {
    height: 41.962px;
    flex-shrink: 0;
    border-radius: 0.7em;
    font-size: 18px;
    line-height: normal;
    cursor: pointer;
    transition: background 0.3s, box-shadow 0.3s;
    width: 20%;
    border: 1.998px solid #ffcb7a;
    background: #ffffff;
    color: #000;
    transition: background 0.3s, color 0.3s;
    font-weight: 500;
    color: black;

    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .login-btn:hover,
  .login-btn:focus {
    border-radius: 12px;
    background: #ffd246;
    box-shadow: 2px -3px 4px 0px rgba(0, 0, 0, 0.25) inset,
      -2px 3px 10px 0px rgba(0, 0, 0, 0.25);
    border: none;
    box-shadow: none !important;
    outline: none;
  }

  .enquiry-btn:hover,
  .enquiry-btn:focus {
    background: #ffcb7a;
    color: #ffffff;
    /* border: none; */
    box-shadow: none !important;
    outline: none;
  }
}

.drop-text {
  background-color: greenyellow;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drp {
  color: #f2f2f2;
  text-decoration: none;
  margin: 10px 10px;
}

.main_div {
  background-color: white;
}
.global-logo {
  width: 170px;
  flex-shrink: 0;
  margin-left: 5px;
}

.text-center {
  text-align: center !important;
}

.login-icon-background {
  margin-top: -19.5px;
  margin-bottom: -7.5px;
  padding-top: 8px;
  /* padding-bottom: 9.5px; */
  width: 30px;
  height: 49px;
  flex-shrink: 0;
  /* background: #ffa923; */
}
.enquiry-btn {
  height: 38.547px;
  flex-shrink: 0;
  border-radius: 12px;
  font-size: 18px;
  line-height: normal;
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s;
  width: 140px;
  border: 2px solid #ffcb7a;
  background: #ffffff;
  color: #000;
  transition: background 0.3s, color 0.3s;
  font-weight: 500;
  color: black;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login-btn {
  border-radius: 12px;
  width: 160px;
  height: 38.547px;
  font-size: 18px;
  background: #ffa923;
  border: 1px solid #ffa923;
  box-shadow: 2px -3px 4px 0px rgba(0, 0, 0, 0.25) inset,
    -2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: start;
  font-weight: 600;
  border: none;
  padding: 0px 20px;
}

@media only screen and (max-width: 991px) {
  .smt {
    background-color: white;
  }
}

.login-btn:hover,
.login-btn:active,
.login-btn:focus {
  border-radius: 12px;
  background: #ffa923 !important;
  box-shadow: 2px -3px 4px 0px rgba(0, 0, 0, 0.25) inset,
    -2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  border: #ffa923;
  outline: #ffa923 !important;
}

.enquiry-btn:hover,
/* .enquiry-btn:active, */
.enquiry-btn:focus {
  background: #ffa923 !important;
  color: #ffffff;
  /* border: none;  */
  border-color: #ffa923;
  outline: none;
  box-shadow: none !important;
}
.logout_inner_icon {
  height: 42px;
  top: -1px;
  right: 18px;
}

.login-icon {
  width: 35px;
  height: 160%;
  flex-shrink: 0;
  fill: #ffcb7a;
  border-radius: 5px 5px 5px 5px;
}
.dropdown-menu.show {
  border-radius: 0;
  width: 100%;

  padding: 0px;
}

.dropdown-menu[data-bs-popper] {
  top: 122%;
  /* background-color: #000; */
  /* opacity:0.9; */
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.nav-link {
  font-weight: 500;
  font-size: 16px;
}

.dropdown-menu {
  margin-top: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0px;
  /* margin:5px 0px ; */
}

.hamburger_dgn {
  outline-style: none;

  border-radius: 5px;
  border: 2px solid #dc1b5e;
}

.header_bx {
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.menu-submenu-container .dropdown-menu {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease; 
}

.menu-submenu-container:hover .dropdown-menu {
  display: block;
  opacity: 1;
}


.upper_head {
  height: 65px;
  position: relative;
}

.lower_head {
  background-color: #dc1b5e;
  position: sticky;
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  z-index: 10;
}

.ul_li {
  list-style: none;
  margin: 0px 70px;
  text-align: center;
}
.txt-link:hover {
  color: #ffa923;
}
.txt-link {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #dc1b5e;
  margin: 0px 20px;
}

.hamburger-menu {
  display: none;
}

.ham_page {
  display: none;
  width: 100%;
  /* min-height: calc(100vh-100px);  */
  min-height: 100vh;
  background-color: white;
  /* border: 2px solid gray; */
  padding: 0px 5px;
}
.invalid-feedback{
  font-size: 11px;
}
.ham_data {
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.lineX {
  height: 2px;
  background-color: #dc1b5e;
  display: none;
  margin: 0px;
}

.navpage2 {
  background-color: white;
  color: black;
  width: 100%;
}
.accr_item {
  margin: 0px !important;
  border-radius: 0% !important;
}
.accr_item:focus {
  color: rgb(255, 255, 255) !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  background-color: #dc1b5e !important;
}
.accr_item:active {
  color: rgb(255, 255, 255) !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  background-color: #dc1b5e !important;
}
.bg-p:focus {
  background-color: #dc1b5e !important;
}
.accr_item2::after {
  content: none !important;
  box-shadow: none !important ;
  margin: 0px !important;
  background-color: #dc1b5e !important;
}

.accr_item2:focus {
  box-shadow: none !important;
  margin: 0px !important;
}

.mar2 {
  margin: 0px 5px 0px 0px;
  padding: 15px 6px;
}

.accr_item2:focus {
  background-color: #dc1b5e !important;
  color: white !important;
}

.navpage2_drp {
  width: 100%;
  color: #000;
  margin: 2px 0px;
  font-size: 26px;
  -webkit-padding-start: 6.6px;
}

.navpage2_drp .nav-link::after {
  display: none;
}

.enquiry-btn2 {
  height: 38.547px;
  flex-shrink: 0;
  border-radius: 12px;
  font-size: 18px;
  line-height: normal;
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s;
  width: 140px;
  border: 2px solid #ffcb7a;
  background: #ffffff;
  color: #000;
  transition: background 0.3s, color 0.3s;
  font-weight: 500;
  color: black;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 10px;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  transition: transform 0.3s ease;
}

.dropdown-toggle:hover::after {
  transform: rotate(180deg);
}


.menu-submenu:hover .dropdown-toggle::after {
  animation: rotateIcon 0.3s linear forwards; 
}

@keyframes rotateIcon {
  from {
    transform: rotate(0deg); 
  }
  to {
    transform: rotate(180deg); 
  }
}



.logout-btn {
  border-radius: 12px;
  width: 160px;
  height: 38.547px;
  font-size: 18px;
  background: #ffa923;
  border: 1px solid #ffa923;
  box-shadow: 2px -3px 4px 0px rgba(0, 0, 0, 0.25) inset,
    -2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: start;
  font-weight: 600;
  border: none;
  padding: 0px 15px;
}
.logout-btn:focus,
.logout-btn:focus-visible {
  background-color: #ffa923 !important;
  box-shadow: none !important;
}
.logout-btn:hover{
  background-color: #ffa923 !important;
  color: #fff;
}
@media only screen and (max-width: 1200px) {
  .txt-link {
    margin: 0px 10px;
    font-size: 105%;
  }
  /* .menubar{
    width:50%;
    margin-right: 38%;
  } */
}
/* @media only screen and (max-width: 995px) {
  .lower_head a{
    font-size: 60%;
  }
  .global-logo{
    height: 80%;
  }
} */

@media only screen and (min-width: 995px) {
  .ham_page {
    display: none !important;
  }
}
@media(max-width: 480px){

  .hamburger-menu {
    display: block;
    position: absolute !important;
    top: 26% !important;
    left: 0%;
    margin: 0px 10px !important;
    border: 2px solid #dc1b5e;
    padding: 4px;  cursor: pointer;
    border-radius: 5px;
  }
  .for-mobileview-header-submenu{
      padding-bottom: 3% !important;
      cursor: pointer;
  }
  .for-mobileview-header-submenu:hover{

      color: #dc1b5e;  cursor: pointer;
  }
  
}
@media only screen and (max-width: 368px) {
  .mob_ham {
    flex-direction: column;
  }
}
@media only screen and (max-width: 995px) {
  .desktop_menu {
    display: none !important;
  }
  .logout-btn {
    /* width: 180px; */
    text-align: start;
    /* padding: 0px 5px;  */
  }
  .login-btn {
    /* width: 380px; */
    text-align: start;
    padding: 0px 25px;
  }
  .upper_head {
    display: flex;
    justify-content: center !important;
    align-items: center;
    position: relative;
    align-items: center;
  }

  .hamburger-menu {
    display: block;
    position: absolute;
    top: 35% !important;
    left: 0%;
    margin: 0px 10px !important;
    border: 2px solid #dc1b5e;
    padding: 4px;
    border-radius: 5px;
  }

  .ham_page {
    z-index: 120;
    margin: 0px;
    position: fixed;

    transition: 0.3s ease-in;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logout_inner_icon {
    width: 35px;
  }
  .lower_head {
    display: none;
  }

  .lineX {
    display: block;
    position: sticky;
    top: 65px;
    z-index: 100;
  }
}

.no_dropdown.nav-item.dropdown .dropdown-menu {
  display: none !important;
}
.no_arrow #basic-nav-dropdown::after {
  display: none !important;
}

.colored-toast.swal2-icon-success {
  background-color: rgb(22, 153, 44) !important;
}

.colored-toast.swal2-icon-error {
  background-color: #db4343 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
  font-size: 15px !important;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}

.swal2-loading {
  animation: spin 2s linear infinite;
}

.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
  font-size: 14px !important;
}

div:where(.swal2-container) div:where(.swal2-loader) {
  border-color: #ffc409 rgba(0, 0, 0, 0) #ffc409 rgba(0, 0, 0, 0) !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  width: 24em !important;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
}
.desktop_menu {
  margin: 5px;
}
.profile-img {
  padding: 19px;
}
.btn-color {
  padding: 12px 42px;
  font-size: 20px;
  border-radius: 10px;
  background: #ffa923;
  margin: 4px 2px;
  color: #fff;
}

.menu-submenu {
  text-transform: capitalize;
  border-bottom: 0.5px solid #dc1b5e !important;
  transition: all 0.3s ease; 
}

.menu-submenu:hover {
  background-color: #dc1b5e;
  color: #f2f2f2;
}

.menubar {
  width: 43%;
  margin-right: 50%;
}

.accr_item:focus::after {
  filter: invert(1);
}

.form-select:focus {
  outline: 0;
  box-shadow: none;
}
  .for-mobileview-header-submenu{ 
    cursor: pointer;
  }