.profile{
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  padding-bottom: 2%;
}
.addonrow {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.addonrow2 {
  display: flex;
  flex-direction: row;
}

.stsadd {
  border-left: 1px solid rgba(181, 181, 181, 1);
}

.totalon {
  display: flex;
  flex-direction: column;
}

.addonpara {
  color: black;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.stspara {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: rgba(107, 107, 107, 1);
}
.paidpara {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: rgba(0, 128, 20, 1);
}

.unpaidpara {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;

  color: rgba(255, 0, 0, 1);
}

.srvicpara {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(107, 107, 107, 1);
}
.srvicpara2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
}

.totalpara {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  color: rgba(107, 107, 107, 1);
  text-align: center;
}

.totalpara2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color:black
}

@media (max-width: 450px) {
  .addonrow {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .totalon {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 10px;
  }

  .addonrow2 {
    display: flex;
    gap: 22px;
  }

  .addonpara {
    color: black;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
  }

  .stspara {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: rgba(107, 107, 107, 1);
  }
  .paidpara {
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: rgba(0, 128, 20, 1);
  }

  .unpaidpara {
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: rgba(255, 0, 0, 1);
  }

  .srvicpara {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(107, 107, 107, 1);
  }
  .srvicpara2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }

  .totalpara {
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    color: rgba(107, 107, 107, 1);
    text-align: right;
    text-wrap: nowrap;
  }

  .totalpara2 {
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
    text-wrap: nowrap;
    color:black
  }
  .totalhead {
    display: flex;
    align-items: end;
    justify-self: end;
    align-items: self-end;
  }
}

.addonrow2 {
  display: flex;
  flex-direction: row;
}
