.detail-h{
 
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  
  }
  
  .detail-h::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
    background-color: rgba(255, 169, 35, 1);
  
    color: white !important;
    border-radius: 50px;
    font-size: medium;
  }
  
  .detail-box{
    background: rgba(245, 245, 245, 0.75);
  
  
  }
  .traveller-input2{

     background-color: none;
  }
  

  
  
  
  .accr_btn{
    border-radius: 15px !important;
    background-color: rgba(245, 245, 245, 0.75) !important;
    border: none !important;
  }
  
  .detail-border{
    border-top: 1px solid black;
  }
  
  /* .accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
    background-color: white;
    border-bottom: 1px solid black;
    margin-bottom: 2rem;} */
    /* border: none !important; */
    /* border: 0.5px solid white !important; */
  
.accr_btn2{
  border-radius: 0;
    background-color: white;
    border-bottom: 1px solid black;
    margin-bottom: 2rem;
}

  .place-trav {
    color: #c2c2c2;
  
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 0.5px solid rgba(0, 0, 0, 1);
    border-radius: 4.847px;
    /* background: #ebebeb; */
  
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }
  
  .para-trav{
    color: linear-gradient(90.02deg, #DC1B5E 1.76%, #FFA923 98.25%);
    
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.013em;
   text-wrap: nowrap;
   background: linear-gradient(to right, #DC1B5E, #FFA923);
  -webkit-background-clip: text; /* WebKit browsers */
  background-clip: text;
  color: transparent;
    
  }

  /* .accordion-button:not(.collapsed)::after {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    transform: rotate(-180deg);
    color: white;
} */




.accordion-item{
  border-top: 0;
  border: 0;
}

.accordion-item.active > .accordion-header button {
  border-bottom: 1px solid #000; /* Add your desired border color and style */
}
.travform{
  border-top: 1px solid black;
}