.forcomapniesbg {
  box-shadow: 0px 0px 10px 2px #c2c2c2;
  border-radius: 6px;
  aspect-ratio: 4/2;
}
.bannerimg {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    filter: brightness(50%);
    object-position: center center;
}
.for_companyimg {
  padding: 32px 92px 0 92px;
}
.for_companyimg2 {
  padding: 22px 80px 0 80px;
}
.for-shadow {
  box-shadow: 0px 0px 4px 1.5px #00000040;
}
.for_childpink {
  position: absolute;
  bottom: -2px;
  right: 40px;
}
.for_parentbgpink {
  position: relative;
}
.for_discover {
  font-size: 25px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-align: justify;
}
.for_discovercontnet {
  text-align: justify;
  font-weight: 400;
  line-height: 23px;
  font-size: 18px;
}
.for-bgcard {
  background-color: white;
  cursor: pointer;
  border: none;
}
.for-bgcard:hover {
  background-color: #dc1b5e;
  color: white;
  border-radius: 0%;
}
.team_head {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
}

.for_loremhead {
  font-size: 29px;
  padding: 0%;
}
.for_loremtext {
  text-align: justify;
  font-size: 17px;
  margin-top: 17px;
  color: #5c5c5c;
}
.read_morebtn {
  font-size: 18px;
  border: 1.5px solid #ffa923;
  line-height: 22px;
  font-weight: 400;
}

.for_team {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  /* font-family: Ubuntu !important; */


}
@media (max-width: 768px) {
  .for_loremtext {
    text-align: justify;
    font-size: 17px;
    margin-top: 20px;
    color: #5c5c5c;
  }
  .for_loremhead {
    font-size: 24px;
    margin-top: 20px;
  }
  .for_team {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
  }
  .bannerimg {
    width: 100%;
    height: 39vh;
    object-fit: cover;
    filter: brightness(50%);
    object-position: center center;
}
}
@media (max-width: 480px) {
  .for_companyimg {
    padding: 57px 92px 0 127px;
  }
  .bannerimg {
    width: 100%;
    height: 18vh;
    object-fit: cover;
    filter: brightness(50%);
    object-position: center center;
}
  .for_companyimg2 {
    padding: 6px 45px 0px 45px;
    text-align: center !important;
    margin: auto;
    width: 100%;
  }
  .for_aboutus {
    font-size: 9vw !important;
  }
  .for_mainbgpink {
    max-width: 95% !important;
    height: auto;
  }
  .for_bgpinkchild {
    top: 53.6% !important;
    width: 96% !important;
    left: 52% !important;
  }
  .for_loremhead {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: justify !important;
}
  
  .for_loremtext {
    text-align: justify;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
  }
  .for-shadow {
    padding: 19px 35px 12px 35px;
  }
  .read_morebtn {
    font-size: 18px;
    border: 1.5px solid #ffa923;
    line-height: 22px;
    font-weight: 400;
    margin-top: -25px !important;
}
 
  .forsmallcopyimg {
    padding: 10px 40px 4px 37px;
    width: 100% !important;
}
.for_team {
    padding: 0% !important;
    line-height: 25px !important;
    font-size: 30px;
}
 .forfounders{
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    padding-top: 2%;
 }
 .forfounders2{
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
 }

}
