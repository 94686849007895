.our {
  color: #dc1b5e;

  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.gallary {
  color: #000;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.gallery-image {
  flex-shrink: 0;
  width: 100%;
}

.dance {
  width: 100%;
  max-width: 825px;
  height: auto;
}

.trip {
  width: 100%; 
  max-width: 255px; 
  height: auto; 
    margin-left: 10px; 
}

.video-bg {
  background-color: rgba(255, 234, 201, 1);
  padding: 0rem;
  margin-bottom: 1rem !important;
}
@media (max-width: 480px) {
  .our {
    color: #dc1b5e;
    font-size: 29px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .for_responsive {
    margin-bottom: 1% !important;
  }
  .video-bg {
    background-color: rgba(255, 234, 201, 1);
    padding: 0% !important;
  }
}
